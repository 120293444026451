import { createAsyncThunk } from "@reduxjs/toolkit";
import { Adminaxios } from "../../services/api/axios";

//////////////////////////////
// CASHIER APIS CONNECTIONS //
//////////////////////////////

import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

export const getRetailerUsersAction = createAsyncThunk(
  "Retailers/getAllRetailerUsers",
  async (thunkApi) => {
    try {
      const response = await Adminaxios.get("cashier/getall");
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else if (error.response && error.response.status === 401) {
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });

        window.location.href = "/login";
      }
    }
  }
);

export const createCashierAction = createAsyncThunk(
  "Retailers/createCashier",
  async (
    {
      firstName,
      lastname,
      role,
      username,
      password,
    }: {
      firstName: string;
      lastname: string;
      username: string;
      password: string;
      role: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("cashier/create", {
        firstname: firstName,
        lastname,
        role,
        username,
        password,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const updateCashierAction = createAsyncThunk(
  "Retailers/updateCashier",
  async (
    {
      firstName,
      lastname,
      username,
      role,
      currentUserId,
    }: {
      firstName: string;
      lastname: string;
      username: string;
      role: string;
      currentUserId: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`cashier/${currentUserId}/update`, {
        firstname: firstName,
        lastname,
        username,
        role,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const changePasswordAction = createAsyncThunk(
  "Retailers/changePassword",
  async (
    {
      newPass,
      currentUserId,
      userType,
    }: { newPass: string; currentUserId: string; userType: string },
    thunkApi
  ) => {
    try {
      if (userType === "cashier") {
        const response = await Adminaxios.put(
          `cashier/${currentUserId}/change_password`,
          { newPass }
        );
        return response.data;
      } else {
        if (userType === "admin") {
          const response = await Adminaxios.put(
            `change_password/${currentUserId}`,
            { newPass }
          );

          return response.data;
        } else {
          const response = await Adminaxios.put(
            `retailer/${currentUserId}/change_password`,
            { password: newPass }
          );
          return response.data;
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const assignCashirToShopAction = createAsyncThunk(
  "Retailers/assignCashirToShop",
  async (
    {
      retailerId,
      currentuserId,
    }: { retailerId: string; currentuserId: string },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`cashier/${currentuserId}/assign`, {
        retailerId,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const unassignCashirAction = createAsyncThunk(
  "Retailers/unassignCashir",
  async ({ currentuserId }: { currentuserId: string }, thunkApi) => {
    try {
      const response = await Adminaxios.put(
        `cashier/${currentuserId}/unassign`,
        {}
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

////////////////////////////////
// RETAILERS APIS CONNECTIONS //
////////////////////////////////

export const getAllRetailersAction = createAsyncThunk(
  "Retailers/getAllRetailers",
  async (thunkApi) => {
    try {
      const response = await Adminaxios.get("retailer/getall");
      if (response && response.data) {
        return response.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else if (error.response && error.response.status === 401) {
        Notification({
          message: "Session Expired",
          type: "error",
        });

        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });

        window.location.href = "/login";
      }
    }
  }
);
export const getOneRetailersAction = createAsyncThunk(
  "Retailers/getAllRetailers",
  async ({ id }: { id: string }, thunkApi) => {
    try {
      const response = await Adminaxios.get(`retailer/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const createRetaileAction = createAsyncThunk(
  "Retailers/createRetaile",
  async (
    {
      password,
      rtp,
      username,
    }: {
      username: string;
      password: string;
      rtp: number;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("retailer/create", {
        password,
        rtp,
        username,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const updateRetailerAction = createAsyncThunk(
  "Retailers/updateRetailer",
  async (
    {
      rtp,
      username,
      retailerId,
    }: {
      rtp: number;
      username: string;
      retailerId: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`retailer/${retailerId}/update`, {
        rtp,
        username,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

export const assignMultipleCashiers = createAsyncThunk(
  "Retailers/assignMultipleCashiers",
  async (
    {
      cashierIds,
      currentuserId,
    }: { cashierIds: string[]; currentuserId: string },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(
        `cashier/${currentuserId}/assign-many`,
        {
          cashierIds,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

export const unassignMuiltiCashirAction = createAsyncThunk(
  "Retailers/assignMultipleCashiers",
  async (
    {
      cashierIds,
      currentuserId,
    }: { cashierIds: string[]; currentuserId: string },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(
        `cashier/${currentuserId}/unassign-many`,
        {
          cashierIds,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

///////////////////////////////////
// BACK OFFICE APIS CONNECTIONS //
/////////////////////////////////

export const getAllAdminAction = createAsyncThunk(
  "Retailers/getAllAdmin",
  async (thunkApi) => {
    try {
      const response = await Adminaxios.get("getall");
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        if (error.response && error.response.status === 402) {
          localStorage.removeItem("token");
          localStorage.removeItem("username");
          Notification({
            message: "Session Expired",
            type: "error",
          });
          window.location.href = "/login";
        } else {
          Notification({
            message: "Something went wrong",
            type: "error",
          });
        }
      }
    }
  }
);

export const createAdminAction = createAsyncThunk(
  "Retailers/createAdmin",
  async (
    {
      firstName,
      lastname,
      username,
      password,
      role,
    }: {
      firstName: string;
      lastname: string;
      username: string;
      password: string;
      role: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("signup", {
        firstname: firstName,
        lastname,
        username,
        password,
        roles: role,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

export const updateAdminAction = createAsyncThunk(
  "Retailers/updateAdmin",
  async (
    {
      firstName,
      lastname,
      username,
      role,
      currentUserId,
    }: {
      firstName: string;
      lastname: string;
      username: string;
      role: string;
      currentUserId: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`${currentUserId}`, {
        firstname: firstName,
        lastname,
        username,
        roles: role,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

export const assignShopToAdminAction = createAsyncThunk(
  "Retailers/assignShopToAdmin",
  async (
    {
      retailerId,
      currentuserId,
      assignedToAll,
    }: { retailerId: string[]; currentuserId: string; assignedToAll: boolean },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`${currentuserId}/assign`, {
        retailerIds: retailerId,
        assignedToAll: assignedToAll,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const unassignShopFromAdminAction = createAsyncThunk(
  "Retailers/unassignShopFromAdmin",
  async (
    {
      currentuserId,
      retailerId,
    }: { currentuserId: string; retailerId: string[] },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.put(`${currentuserId}/unassign`, {
        retailerIds: retailerId,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

//////////////////////////////////
// REPORTS APIS CONNECTIONS    //
////////////////////////////////

export const getRetailRevenueAction = createAsyncThunk(
  "Reports/getRetailRevenue",
  async (
    {
      startDate,
      endDate,
      games,
      retailers,
      slipReference,
    }: {
      startDate: string;
      endDate: string;
      games: string[];
      retailers: string[];
      slipReference: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("reports/retail", {
        startDate,
        endDate,
        games,
        retailers,
        slipReference,
      });

      if (response && response.data !== "") {
        return response.data;
      } else {
        Notification({
          message: "No Records found",
          type: "error",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
export const getRetailUserRevenueAction = createAsyncThunk(
  "Reports/getRetailUserRevenue",
  async (
    {
      startDate,
      endDate,
      games,
      retailers,
      cashiers,
      slipReference, //TO-DO : add slipref to the request
    }: {
      startDate: string;
      endDate: string;
      games: string[];
      retailers: string[];
      cashiers: string[];
      slipReference: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("reports/retail_user", {
        startDate,
        endDate,
        games,
        retailers,
        cashiers,
        slipReference,
      });

      if (response && response.data !== "") {
        return response.data;
      } else {
        Notification({
          message: "No Records found",
          type: "error",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);

/////////////////////////////////
// FIND BETS APIS CONNECTIONS //
///////////////////////////////
export const getFindBetsAction = createAsyncThunk(
  "Reports/getFindBets",
  async (
    {
      startDate,
      endDate,
      retailers,
      games,
      betType,
      betStatus,
      cancelledBy,
      redeemedBy,
      submittedBy,
      betId,
      slipReference,
    }: {
      startDate: string;
      endDate: string;
      retailers: string[];
      games: string[];
      betType: string[];
      betStatus: string[];
      cancelledBy: string[];
      redeemedBy: string[];
      submittedBy: string[];
      betId: string;
      slipReference: string;
    },
    thunkApi
  ) => {
    try {
      const response = await Adminaxios.post("find-bets", {
        startTime: startDate,
        endTime: endDate,
        retailers,
        games,
        betType,
        betStatus,
        cancelledBy,
        redeemedBy,
        submittedBy,
        slipReference,
        betId,
      });

      if (response && response.data !== "No results found") {
        return response.data;
      } else {
        Notification({
          message: "No Records found",
          type: "error",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 402) {
        localStorage.removeItem("token");
        localStorage.removeItem("username");
        Notification({
          message: "Session Expired",
          type: "error",
        });
        window.location.href = "/login";
      } else {
        Notification({
          message: "Something went wrong",
          type: "error",
        });
      }
    }
  }
);
