import axios from "axios";

const Authaxios = axios.create({
  baseURL: "https://api.games.dytech-services.com/v1/admin/login",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  },
});

const token = localStorage.getItem("token");

export const Adminaxios = axios.create({
  baseURL: "https://api.games.dytech-services.com/v1/admin/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Bearer ${token}`,
  },
});

export default Authaxios;
