import { createSlice } from "@reduxjs/toolkit";
import { getRetailerUsersAction,getAllRetailersAction,getAllAdminAction } from "./retailerAction"; 
import { UserInteraface } from "../../models/retailUser";

const initialState: UserInteraface = {
 
  retailUsers: [],
  retailers:[],
  admins:[],
  
};

const RetailerSlice = createSlice({
  name: "Retailer",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(getRetailerUsersAction.fulfilled, (state: UserInteraface, action) => {
      if(action.payload!==undefined)state.retailUsers=action.payload
    });
    // builder.addCase(createCashier.fulfilled, (state: UserInteraface, action) => {
    
    // });
    builder.addCase(getAllRetailersAction.fulfilled, (state: UserInteraface, action) => {
      if(action.payload!==undefined) state.retailers=action.payload
    });
    builder.addCase(getAllAdminAction.fulfilled, (state: UserInteraface, action) => {
      if(action.payload!==undefined) state.admins=action.payload
    });

  },
});

// export const RetailerUserInfo = (state) => state.Retailer;
// export const { increment } = RetailerSlice.actions;
export default RetailerSlice.reducer;
