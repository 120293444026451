import { useEffect, useState } from "react";
import Button from "components/button";
import Modal from "../components/modal";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores/store";
import { changePasswordAction } from "stores/ReatilerUser/retailerAction";
import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

interface PasswordChangeModalInterface {
  open: boolean;
  id: string;
  userType: string;
  onClose: () => void;
}

const ChangePassword = ({
  open,
  id,
  userType,
  onClose,
}: PasswordChangeModalInterface) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repassWord, setRePassWord] = useState("");

  const dispatch = useAppDispatch();

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== "" && repassWord !== "") {
      if (newPassword === repassWord) {
        if (newPassword.length > 3) {
          try {
            const response = await dispatch(
              changePasswordAction({
                newPass: newPassword,
                currentUserId: id,
                userType: userType,
              })
            );
            if (response.payload) {
              Notification({
                message: "Password changed successfully",
                type: "success",
              });
              onClose();
            } else {
              Notification({
                message: "Something went wrong",
                type: "error",
              });
            }
          } catch (error) {
            Notification({
              message: "Something went wrong",
              type: "error",
            });
          }
        } else {
          Notification({
            message: "Password must be at least 4 characters long",
            type: "error",
          });
        }
      } else {
        Notification({
          message: "Passwords do not match!",
          type: "error",
        });
      }
    } else {
      Notification({
        message: "All fields are required",
        type: "error",
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <h1 className="mb-4 text-2xl">Change Password</h1>
      <form
        className="flex flex-col items-start gap-4 px-4"
        onSubmit={(e) => {
          handleChangePassword(e);
        }}
      >
        <div className="flex flex-col gap-2 ">
          <label className="text-sm font-bold" htmlFor="new">
            New Password: <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            name="new"
            id="new"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            className="px-2 py-1 border-2 rounded-md md:w-96"
          />
        </div>
        <div className="flex flex-col gap-2 ">
          <label className="text-sm font-bold" htmlFor="re">
            Retype Password: <span className="text-red-500">*</span>
          </label>
          <input
            type="password"
            name="re"
            id="re"
            value={repassWord}
            onChange={(e) => setRePassWord(e.target.value)}
            className="px-2 py-1 border-2 rounded-md md:w-96"
          />
          <h2 className="px-2 py-1 text-sm text-gray-700 md:w-96">
            <span className="text-red-500">*</span> Required
          </h2>
        </div>
        <Button variant="secondary">Change Password</Button>
      </form>
    </Modal>
  );
};

export default ChangePassword;
