import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "stores/store";
import { Tab } from "@headlessui/react";
import { CheckBox, Table } from "components";
import Button from "components/button";
import { assigned_users, un_assigned_users } from "mock/table_data";
import DropDown from "utils/drop-down";
import { FaPlus } from "react-icons/fa";
import AddUserModal from "components/add_user_modal";
import { useLocation } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { Fragment } from "react";
import { classNames } from "utils/classes";
import ChangePassword from "./change-password";
import {
  updateRetailerAction,
  assignMultipleCashiers,
  getAllRetailersAction,
  getRetailerUsersAction,
  unassignMuiltiCashirAction,
} from "stores/ReatilerUser/retailerAction";
import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const General = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const retailers = useAppSelector((state) => state.retailer.retailers);
  const [currentUser, setCurrentUser] = useState<any>({});
  const [userName, setUserName] = useState("");
  const [rtp, setrtp] = useState<number>();

  const handleSetRtp = (e) => {
    let inputValue = e.replace(/[^0-9]/g, "");

    if (inputValue < 101) {
      setrtp(inputValue);
    }
  };

  const handleUpdateUser = async () => {
    if (rtp >= 0 && userName !== "") {
      try {
        const response = await dispatch(
          updateRetailerAction({
            rtp,
            username: userName,
            retailerId: currentUser._id,
          })
        );
        if (response.payload) {
          Notification({
            message: "Successfully updated Retailer",
            type: "success",
          });
        } else {
          Notification({
            message: "Something went wrong!",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Something went wrong!",
          type: "error",
        });
      }
    } else {
      Notification({
        message: "Please fill all the required fields",
        type: "error",
      });
    }
  };

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");
    retailers.length > 0 &&
      setCurrentUser(retailers.find((user) => user._id === rowParam));
    setUserName(currentUser.username);
    setrtp(currentUser.rtp);
  }, [currentUser]);

  return (
    <div>
      <h1>
        GUID <span className="text-red-500">*</span>
      </h1>
      <h2 className="font-[600]">{currentUser._id}</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-5">
        <div className="flex flex-col">
          <label htmlFor="name">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            name="name"
            id="name"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>

        <div>
          <label htmlFor="rtp">
            RTP <span className="text-red-500">*</span>
          </label>
          <input
            name="rtp"
            id="rtp"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="number"
            value={rtp}
            onChange={(e) => handleSetRtp(e.target.value)}
          />
        </div>
      </div>
      <div className="flex justify-end py-5 mt-5 border-t-2 ">
        <Button onClick={handleUpdateUser}>
          <div>Update</div>
        </Button>
      </div>
      <div className="flex items-center justify-end gap-4">
        <span className="text-red-500">*</span> Required
      </div>
    </div>
  );
};

const RetailUsers = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>([]);
  const [key, setKey] = useState(0);
  const [retailId, setRetailId] = useState<any>({});
  const [currentUser, setCurrentUser] = useState<any>({});

  const retailUser = useAppSelector((state) => state.retailer.retailUsers);
  const retailers = useAppSelector((state) => state.retailer.retailers);

  const dispatch = useAppDispatch();
  const location = useLocation();

  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkedRowsToUnassign, setcheckedRowsToUnassign] = useState<any[]>([]);

  const [showChangePass, setShowChangePass] = useState(false);
  const [passwordChangeId, setpasswordChangeId] = useState("");

  const handleclosePasswordChange = () => {
    setShowChangePass((prev) => !prev);
    setpasswordChangeId("");
  };
  const handleOpenPasswordChange = (id) => {
    setpasswordChangeId(id);
    setShowChangePass((prev) => !prev);
  };

  const handleShow = () => {
    setShow((prev) => !prev);
  };

  const handleAssignMultipleUsers = async () => {
    var selectedShopsId = checkedRows.map((item) => item._id); //filter the Ids from the object array
    try {
      const response = await dispatch(
        assignMultipleCashiers({
          cashierIds: selectedShopsId,
          currentuserId: currentUser._id,
        })
      );

      if (response.payload) {
        Notification({
          message: "Successfully Assigned users",
          type: "success",
        });
        window.history.back();
      } else {
        Notification({
          message: "Something went wrong!",
          type: "error",
        });
      }
    } catch (error) {
      Notification({
        message: "Something went wrong!",
        type: "error",
      });
    }
  };

  const handleUnassign = async () => {
    if (checkedRowsToUnassign.length > 0) {
      var selectedUsersId = checkedRowsToUnassign.map((item) => item._id);
      try {
        const response = await dispatch(
          unassignMuiltiCashirAction({
            cashierIds: selectedUsersId,
            currentuserId: currentUser._id,
          })
        );
        if (response.payload) {
          Notification({
            message: "Successfully Unassigned users",
            type: "success",
          });
          window.history.back();
        } else {
          Notification({
            message: "Something went wrong!",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Something went wrong!",
          type: "error",
        });
      }
    }
  };

  const handlecheckedrow = (row: any) => {
    setCheckedRows((prevRows) => [...prevRows, row]);
  };

  const handlecheckedrowToUnassign = (row: any) => {
    setcheckedRowsToUnassign((prevRows) => [...prevRows, row]);
  };

  useEffect(() => {
    //Remove Duplicate from assign
    const uniqueIds = new Set();
    checkedRows.some((obj) => {
      if (uniqueIds.has(obj._id)) {
        setCheckedRows((prevRows) =>
          prevRows.filter((prevRow) => String(prevRow._id) !== String(obj._id))
        );
      }
      uniqueIds.add(obj._id);
    });
    setKey((prevKey) => prevKey + 1);
  }, [checkedRows]);

  useEffect(() => {
    //Remove Duplicate from unssign
    const uniqueIds = new Set();
    checkedRowsToUnassign.some((obj) => {
      if (uniqueIds.has(obj._id)) {
        setcheckedRowsToUnassign((prevRows) =>
          prevRows.filter((prevRow) => String(prevRow._id) !== String(obj._id))
        );
      }
      uniqueIds.add(obj._id);
    });
    setKey((prevKey) => prevKey + 1);
  }, [checkedRowsToUnassign]);

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");
    retailers.length > 0 &&
      setCurrentUser(retailers.find((user) => user._id === rowParam));
  }, [currentUser]);

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    setRetailId(queryParams.get("param1"));
    setData(retailUser.filter((user) => user.assignedTo === retailId));
    setKey((prevKey) => prevKey + 1);
  }, [retailId]);

  return (
    <div className="flex flex-col gap-5">
      {show && <AddUserModal open={show} onClose={handleShow} />}
      {showChangePass && (
        <ChangePassword
          open={showChangePass}
          id={passwordChangeId}
          userType="cashier"
          onClose={handleclosePasswordChange}
        />
      )}
      <div className="flex flex-col gap-5 ">
        <h1>Assigned Users (Total: {data.length})</h1>
        <div className="flex flex-col gap-5 p-4 bg-gray-200">
          <div className="flex justify-end">
            <Button onClick={handleShow}>
              <div className="flex items-center gap-2 px-2 py-1 text-white rounded-md bg-primary">
                <FaPlus className="text-white" />
                Add User
              </div>
            </Button>
          </div>
          <Table
            key={key}
            data={data}
            columns={[
              {
                header: "",
                id: "checkbox",
                size: 10,
                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrowToUnassign(row.row.original);
                  };
                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        checked={
                          checkedRowsToUnassign.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  );
                },
              },

              {
                header: "Retailer Name",
                accessorKey: "username",
              },

              {
                id: "action",
                header: "",
                cell: (row: any) => {
                  const selectedRowdata = `${encodeURIComponent(
                    JSON.stringify(row.cell.row._valuesCache)
                  )}`;

                  return (
                    <>
                      <button
                        onClick={() =>
                          handleOpenPasswordChange(row.row.original._id)
                        }
                        className="block px-4 py-2 text-sm text-white bg-green-600 border rounded"
                      >
                        Change password
                      </button>
                    </>
                  );
                },
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handleUnassign}>
            <div>Unassign Selected</div>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-5 ">
        <h1>Unassigned Users</h1>
        <div>
          <Table
            key={key}
            data={retailUser.filter((user) => user.assignedTo === null)}
            columns={[
              {
                header: "",
                id: "checkbox",
                size: 10,

                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrow(row.row.original);
                  };

                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        onChange={handleCheckboxChange}
                        checked={
                          checkedRows.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                      />
                    </div>
                  );
                },
              },
              {
                header: "name",
                accessorKey: "username",
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handleAssignMultipleUsers}>
            <div>Assign Selected</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  { name: "General", tab: <General /> },
  // { name: " Logo", tab: <div></div> },
  // { name: "Currencies", tab: <div></div> },
  // { name: "Commission", tab: <div></div> },
  { name: "Retail Users", tab: <RetailUsers /> },
  // { name: " Operating Hours", tab: <div></div> },
  // { name: "Settings", tab: <div></div> },
];

const RetailerDetail = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const retailers = useAppSelector((state) => state.retailer.retailers);
  const location = useLocation();

  // role based tab hidding
  const tabList =
    localStorage.getItem("userRole") == "retail_finance"
      ? tabs.filter((tab) => tab.name != "Retail Users")
      : tabs;

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");
    retailers.length > 0 &&
      setCurrentUser(retailers.find((user) => user._id === rowParam));
  }, [currentUser]);

  return (
    <div key={retailers.length}>
      <h1 className="font-[600]">Manage Retailer</h1>
      <h2>{currentUser.username}</h2>
      <div className="p-1 bg-gray-300">
        <Tab.Group>
          <Tab.List className="bg-gray-300">
            {tabList.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) => `border border-gray-500
                px-4 py-2 rounded-md 
                rounded-b-none
                focus:outline-none focus:ring-transparent ring-offset-2 ring-offset-gray-300 ring-white
                ${selected ? "bg-white border-b-white" : "bg-gray-300"}
                `}
              >
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="px-5 bg-white border-gray-500">
            {tabList.map((tab) => (
              <Tab.Panel key={tab.name} className="p-5 rounded-md ">
                {tab.tab}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="flex justify-center mt-5">
        <Button variant="secondary" onClick={() => window.history.back()}>
          <div>Back to List</div>
        </Button>
      </div>
    </div>
  );
};

export default RetailerDetail;
