import { createAsyncThunk } from "@reduxjs/toolkit";
import Authaxios from "../../services/api/axios"

export const LoginAction = createAsyncThunk(
  "user/login",
  async ({ username, password }: { username: string; password: string }, thunkApi) => {
    
      const response = await Authaxios.post("", {
        username,
        password,
      });
      return response.data;
  }
);
