import { createSlice } from "@reduxjs/toolkit";
import { LoginAction } from "./authAction";
import { User } from "../../models/user";

const initialState: User = {
  userInfo: [],
};

const userSlice = createSlice({
  name: "User",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //login user

    builder.addCase(LoginAction.fulfilled, (state: User, action) => {
      
      return {
        ...state,
        userInfo: [action.payload],
      };
    });
  },
});

// export const currentUsers = (state) => state.User;
export default userSlice.reducer;
