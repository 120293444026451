const Button = ({
  children,
  variant = "primary",
  onClick,
  disabled = false,
}: {
  children: React.ReactNode;
  variant?: "primary" | "secondary";
  disabled?: boolean;
  onClick?: () => void;
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`"inline-flex items-center md:px-4 md:py-2 px-2 py-2 border border-transparent rounded-md text-sm font-medium text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500
      ${
        variant === "primary"
          ? "bg-primary hover:bg-opacity-80 disabled:bg-slate-500"
          : "bg-gray-600 hover:bg-gray-700 disabled:bg-slate-500"
      }
      "`}
    >
      {children}
    </button>
  );
};

export default Button;
