import { useState } from "react";
import Button from "components/button";
import Footer from "components/footer.component";
import { FaCircleUser, FaLock } from "react-icons/fa6";
import { LoginAction } from "stores/Auth/authAction";
import { useAppDispatch } from "stores/store";
import { toast } from "react-toastify";

const Login = () => {
  const [userName, setUserName] = useState("");
  const [passWord, setPassWord] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const Notification = ({
    message,
    type,
  }: {
    message: string;
    type: string;
  }) => {
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        LoginAction({ username: userName, password: passWord })
      );
      if (response.payload) {
        setLoading(false);
        localStorage.setItem("token", response.payload.token);
        localStorage.setItem("username", response.payload.username);
        localStorage.setItem("userId", response.payload._id);
        localStorage.setItem("userRole", response.payload.roles);
        window.location.href = "/";
      } else {
        Notification({
          message: "Invalid username or password",
          type: "error",
        });
        setLoading(false);
      }
    } catch (error) {
      Notification({
        message: "something went wrong, try again later",
        type: "error",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col items-center justify-center gap-10 mt-10">
        <div className="flex items-center flex-shrink-0">
          <p
            className="text-2xl font-bold text-center"
            style={{ color: "#7f1f18" }}
          >
            Et <span style={{ color: "#e15a0f" }}>Virtual Admin</span>
          </p>
        </div>
        <div className="flex flex-col gap-4 px-10 py-5 border-2 rounded-md">
          <h1 className="text-2xl font-bold text-center">Login</h1>
          <div className="px-2">
            <div
              className="relative flex items-center py-2 mx-auto"
              style={{ width: "250px" }}
            >
              <FaCircleUser
                className="absolute text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={15}
              />
              <input
                className="w-full py-2 pl-10 leading-tight text-gray-700 bg-transparent border-2 rounded focus:outline-none focus:ring-primary focus:ring"
                placeholder="Username"
                type={"text"}
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>
            <div className="relative flex items-center py-2 mx-auto mb-4">
              <FaLock
                className="absolute text-2xl text-gray-400 transform -translate-y-1/2 left-3 top-1/2"
                size={15}
              />
              <input
                className="w-full py-2 pl-10 leading-tight text-gray-700 bg-transparent border-2 rounded pl- focus:outline-none focus:ring-primary focus:ring"
                placeholder="Password"
                type={"password"}
                value={passWord}
                onChange={(e) => setPassWord(e.target.value)}
              />
            </div>
            <div className="flex items-center justify-center gap-4">
              <button
                onClick={handleLogin}
                disabled={loading}
                className="w-full px-4 py-2 text-white bg-gray-600 rounded-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark focus:ring-opacity-50"
              >
                {loading ? (
                  <svg
                    aria-hidden="true"
                    className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                ) : (
                  <span>Login</span>
                )}
              </button>

              {/* <Button variant="secondary">
                <span>Reset Password</span>
              </Button> */}
            </div>
            <div className="text-sm mt-7 " style={{ color: "#7f1f18" }}>
              Forgot Password? Contact your admin
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;
