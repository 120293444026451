export const MOCK_DATA1 = [
  {
    betting_summary_id: 1,
    game_id: 1,
    money: 136675.52,
    player_id: 1,
    date: "7/18/2020",
  },
  {
    betting_summary_id: 2,
    game_id: 2,
    money: 98253.01,
    player_id: 2,
    date: "4/12/2021",
  },
  {
    betting_summary_id: 3,
    game_id: 3,
    money: 626013.02,
    player_id: 3,
    date: "7/20/2021",
  },
  {
    betting_summary_id: 4,
    game_id: 4,
    money: 833564.34,
    player_id: 4,
    date: "3/9/2021",
  },
  {
    betting_summary_id: 5,
    game_id: 5,
    money: 767429.63,
    player_id: 5,
    date: "7/12/2020",
  },
  {
    betting_summary_id: 6,
    game_id: 6,
    money: 1739.06,
    player_id: 6,
    date: "3/2/2021",
  },
  {
    betting_summary_id: 7,
    game_id: 7,
    money: 477931.34,
    player_id: 7,
    date: "5/24/2020",
  },
  {
    betting_summary_id: 8,
    game_id: 8,
    money: 96879.68,
    player_id: 8,
    date: "4/26/2020",
  },
  {
    betting_summary_id: 9,
    game_id: 9,
    money: 71406.42,
    player_id: 9,
    date: "5/17/2020",
  },
  {
    betting_summary_id: 10,
    game_id: 10,
    money: 672522.7,
    player_id: 10,
    date: "6/16/2021",
  },
  {
    betting_summary_id: 11,
    game_id: 11,
    money: 719067.56,
    player_id: 11,
    date: "5/26/2021",
  },
  {
    betting_summary_id: 12,
    game_id: 12,
    money: 161336.85,
    player_id: 12,
    date: "1/28/2021",
  },
  {
    betting_summary_id: 13,
    game_id: 13,
    money: 443047.84,
    player_id: 13,
    date: "10/27/2020",
  },
  {
    betting_summary_id: 14,
    game_id: 14,
    money: 6268.78,
    player_id: 14,
    date: "8/9/2021",
  },
  {
    betting_summary_id: 15,
    game_id: 15,
    money: 366797.14,
    player_id: 15,
    date: "2/9/2020",
  },
  {
    betting_summary_id: 16,
    game_id: 16,
    money: 489193.16,
    player_id: 16,
    date: "11/12/2021",
  },
  {
    betting_summary_id: 17,
    game_id: 17,
    money: 748799.4,
    player_id: 17,
    date: "6/19/2020",
  },
  {
    betting_summary_id: 18,
    game_id: 18,
    money: 463447.06,
    player_id: 18,
    date: "12/28/2020",
  },
  {
    betting_summary_id: 19,
    game_id: 19,
    money: 915179.9,
    player_id: 19,
    date: "4/28/2021",
  },
  {
    betting_summary_id: 20,
    game_id: 20,
    money: 238148.06,
    player_id: 20,
    date: "1/16/2021",
  },
  {
    betting_summary_id: 21,
    game_id: 21,
    money: 33677.77,
    player_id: 21,
    date: "1/7/2021",
  },
  {
    betting_summary_id: 22,
    game_id: 22,
    money: 335255.42,
    player_id: 22,
    date: "1/21/2021",
  },
  {
    betting_summary_id: 23,
    game_id: 23,
    money: 423680.02,
    player_id: 23,
    date: "10/2/2020",
  },
  {
    betting_summary_id: 24,
    game_id: 24,
    money: 627199.76,
    player_id: 24,
    date: "1/2/2020",
  },
  {
    betting_summary_id: 25,
    game_id: 25,
    money: 68275.9,
    player_id: 25,
    date: "9/28/2020",
  },
  {
    betting_summary_id: 26,
    game_id: 26,
    money: 649743.76,
    player_id: 26,
    date: "8/20/2020",
  },
  {
    betting_summary_id: 27,
    game_id: 27,
    money: 179261.61,
    player_id: 27,
    date: "3/27/2021",
  },
];

export const MOCK_DATA2 = [];

export const OPERATORGROUP = ["Convex Retail Group Billing"];
export const OPERATOR = ["Cross Sport Betting"];
export const REPORT_TYPE_DROP_DOWN = ["Retailer", "Game and Market", "Game"];
export const BET_TYPE_DROP_DOWN = ["Combination", "Multiple", "Single"];
export const DISPLAYCURRENCY = [
  "ETB - Br - Ethiopian Birr",
  "EUR - € - Euro",
  "GBP - £ - British Pound",
  "USD - $ - US Dollar",
  "ZAR - R - South African Rand",
  "ALL - L - Albanian Lek",
  "DZD - دج - Algerian Dinar",
  "AOA - Kz - Angolan Kwanza",
  "ARS - $ - Argentine Peso",
  "AMD - ֏ - Armenian Dram",
  "AWG - ƒ - Aruban Florin",
  "AUD - $ - Australian Dollar",
  "AZN - ₼ - Azerbaijani Manat",
  "BSD - $ - Bahamian Dollar",
];
export const GAME_DROP_DOWN = ["Keno", "SpinAndWin"];
export const RETAILERS = ["Cross Sport Betting"];
export const RETAIL_USERS = ["Cross Sport Betting"];

export const HIGHEST_PAYOUT = [
  {
    betting_id: "1",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "2",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "3",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "4",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "5",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "6",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "7",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
  {
    betting_id: "8",
    name: "Keno",
    player_id: "116923",
    date: "2023/11/22 10:08:08",
    ammount: "Br 1,000,000.00",
  },
];

export const EXCHANGE_RATES = [
  {
    from_currency_code: "AED",
    exchange_rate: "15.145",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "ALL",
    exchange_rate: "0.5866078",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "AMD",
    exchange_rate: "0.002",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "ANG",
    exchange_rate: "1.79",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "AOA",
    exchange_rate: "0.0016",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "ARS",
    exchange_rate: "0.0095",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "AUD",
    exchange_rate: "1.34",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "AWG",
    exchange_rate: "1.79",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "AZN",
    exchange_rate: "0.588",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BAM",
    exchange_rate: "0.5866078",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BBD",
    exchange_rate: "0.5",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BDT",
    exchange_rate: "0.0118",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BGN",
    exchange_rate: "0.5866078",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BHD",
    exchange_rate: "2.65",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BIF",
    exchange_rate: "0.0005",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BMD",
    exchange_rate: "1",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BND",
    exchange_rate: "0.74",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BOB",
    exchange_rate: "0.144",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BRL",
    exchange_rate: "0.18",
    unofficial_exchange_rate: "",
  },
  {
    from_currency_code: "BSD",
    exchange_rate: "1",
    unofficial_exchange_rate: "",
  },
];

export const RETAIL_REVENUE = [
  {
    retailer: "Cross Sport Betting",
    operator: "Convex Retail Group Billing",
    game: "Keno",
    market: "Ethiopia",
    rtp: 0.8,
    bets: 56,
    bet_count: 100,
    slip_count: 23,
    bet_winnings: 0,
    bet_winning_count: 0,
    cancellation: 0,
    cancellation_count: 0,
    unclaimed_bet_winnings: 0,
    unclaimed_bet_winning_count: 0,
    redeemed: 0,
    redeemed_count: 0,
    stake_tax: 0,
    winnings_tax: 0,
    ggr: 0,
    ngr: 0,
    jackpot_winnings: 0,
    jackpot_winning_count: 0,
    unclaimed_jackpot_winnings: 0,
    unclaimed_jackpot_winning_count: 0,
    bonus_winnings: 0,
    vat: 0,
    ggr_tax: 0,
    ngr_tax: 0,
  },
];

export const RETAIL_USER_REVENUE = [
  {
    retailer: "Cross Sport Betting",
    operator: "Convex Retail Group Billing",
    game: "Keno",
    market: "Ethiopia",
    rtp: 0.8,
    bets: 56,
    bet_count: 100,
    slip_count: 23,
    bet_winnings: 0,
    bet_winning_count: 0,
    cancellation: 0,
    cancellation_count: 0,
    unclaimed_bet_winnings: 0,
    unclaimed_bet_winning_count: 0,
    redeemed: 0,
    redeemed_count: 0,
    stake_tax: 0,
    winnings_tax: 0,
    ggr: 0,
    ngr: 0,
    jackpot_winnings: 0,
    jackpot_winning_count: 0,
    unclaimed_jackpot_winnings: 0,
    unclaimed_jackpot_winning_count: 0,
    bonus_winnings: 0,
    vat: 0,
    ggr_tax: 0,
    ngr_tax: 0,
  },
];

export const RETAIL_BET_TABLE_DATA = [];

export const MARKET_DROP_DOWN = [
  "0 Colors",
  "1st Ball",
  "1st Ball",
  "1st Ball Outcome",
  "1st Colour",
  "1st Innings 1st Wicket",
  "Dozens",
  "Exact Match",
];

export const BET_STATUS = [
  "cancelled",
  "lost",
  "pending",
  "redeemed",
  "won-awaiting Redemption",
];

export const TRANSACTION_TYPE = [
  "Betslip Cancelled",
  "Betslip Jackpot won",
  "Betslip Redeemed",
  "Betslip Refund Allocated",
  "Betslip Refund Paid",
  "Betslip Submitted",
  "Betslip Won",
  "Cash Deposit",
  "Cash Transfer",
  "Cash Withdrwal",
  "Cash Zero Bal",
  "Commission Allocated",
  "Commision Reversed",
  "Credit Deposit",
  "Credit Transfer",
  "Credit Withdrawal",
  "Pay Agent Commision",
  "Pay Cashier Commission",
];
