import React, { useState, useEffect } from "react";
import exportFromJSON from "export-from-json";
import { useAppDispatch } from "stores/store";
import {
  getRetailerUsersAction,
  getAllRetailersAction,
} from "stores/ReatilerUser/retailerAction";
import { Table } from "components";
import AddUserModal from "components/add_user_modal";
import Button from "components/button";
import { BsFileEarmarkSpreadsheet, BsPlus } from "react-icons/bs";
import ChangePassword from "./change-password";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { Fragment } from "react";
import { classNames } from "utils/classes";

const RetailUsers = () => {
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [key, setKey] = useState(0);
  const [loading, setLoading] = useState(true);

  const [showChangePass, setShowChangePass] = useState(false);
  const [passwordChangeId, setpasswordChangeId] = useState("");

  const dispatch = useAppDispatch();

  const handleclosePasswordChange = () => {
    setShowChangePass((prev) => !prev);
    setpasswordChangeId("");
  };
  const handleOpenPasswordChange = (id) => {
    setpasswordChangeId(id);
    setShowChangePass((prev) => !prev);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await dispatch(getRetailerUsersAction());
        await dispatch(getAllRetailersAction());
        if (response && response.payload) {
          setData(response.payload);
        }
        setKey((prevKey) => prevKey + 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [show]);

  // implementation of exporting
  const exportData = () => {
    const fileName = "retail-user-data";
    if (!data) {
      throw new Error("No data provided for export.");
    }
    try {
      const exportType = exportFromJSON.types["csv"];
      exportFromJSON({ data, fileName, exportType });
    } catch (e) {
      console.error("Export failed:");
    }
  };

  return (
    <main className="p-4">
      {loading && (
        <div className="absolute bg-gray-500 rounded-full top-1/3 left-1/2">
          <svg
            aria-hidden="true"
            className="w-16 h-16 p-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
            viewBox="0 0 100 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
      )}
      {show && (
        <AddUserModal
          open={show}
          onClose={() => {
            setShow((prev) => !prev);
          }}
          userType="cashier"
        />
      )}
      {showChangePass && (
        <ChangePassword
          open={showChangePass}
          id={passwordChangeId}
          userType="cashier"
          onClose={handleclosePasswordChange}
        />
      )}
      <h1 className="text-xl">
        Retail Users{" "}
        <span className="text-sm font-normal text-gray-500">
          {" "}
          (Total: {data.length})
        </span>
      </h1>
      <div className="w-full bg-gray-200">
        <div className="flex justify-between gap-2 p-4 my-4">
          <Button variant="secondary" onClick={exportData}>
            <div className="flex items-center gap-2">
              <BsFileEarmarkSpreadsheet />
              <span>Export to Excel</span>
            </div>
          </Button>
          <Button
            onClick={() => {
              setShow((prev) => !prev);
            }}
          >
            <div className="flex items-center gap-2">
              <BsPlus className="text-xl font-bold" />
              <span>Add User</span>
            </div>
          </Button>
        </div>
        {
          <Table
            key={key}
            isFilterable={true}
            data={data}
            columns={[
              {
                header: "FullName",
                id: "FullName",
                cell: (row: any) => {
                  return (
                    <p>
                      {row.row.original.firstname} {row.row.original.lastname}
                    </p>
                  );
                },
              },
              {
                header: "Username",
                accessorKey: "username",
              },

              {
                header: "Is Locked",
                accessorKey: "isLocked",
              },
              {
                header: "Roles",
                accessorKey: "role",
              },
              {
                header: " Created",
                accessorKey: "created_at",
                cell: (row: any) => {
                  // const createdAtValue = row.original.created_at;
                  var date = new Date(
                    row.row.original.created_at
                  ).toLocaleDateString("en-GB");

                  return (
                    <div>
                      <p>{date}</p>
                    </div>
                  );
                },
              },
              {
                id: "action",
                header: "",
                cell: (row: any) => {
                  const selectedRowdata = `${encodeURIComponent(
                    JSON.stringify(row.cell.row._valuesCache)
                  )}`;
                  return (
                    <Menu
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                      as="div"
                      className="relative text-left"
                    >
                      <div>
                        <Menu.Button className="inline-flex items-center justify-start w-full px-3 py-2 font-bold text-black bg-gray-400 rounded-md xmd:justify-center gap-x-1 text-md">
                          <span>Action (s)</span>
                          {
                            <BiChevronDown
                              className="w-5 h-5 -mr-1 "
                              aria-hidden="true"
                            />
                          }
                        </Menu.Button>
                      </div>

                      {
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="right-0 z-10 w-full py-2 mt-2 origin-top-left bg-gray-400 rounded-md ring-0 focus:outline-none xmd:absolute xmd:w-44">
                            <Menu.Item>
                              {({ active }) => (
                                <>
                                  <Link
                                    to={{
                                      pathname: "/Retail-user-detail",
                                      search: `?param1=${row.row.original._id}`,
                                    }}
                                    className={classNames(
                                      active
                                        ? "bg-secondary text-gray-100"
                                        : "text-black",
                                      "block px-4 py-2 text-sm font-bold"
                                    )}
                                  >
                                    View
                                  </Link>
                                  <button
                                    onClick={() =>
                                      handleOpenPasswordChange(
                                        row.row.original._id
                                      )
                                    }
                                    className="block px-4 py-2 text-sm font-bold text-black"
                                  >
                                    Change password
                                  </button>
                                </>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      }
                    </Menu>
                  );
                },
              },
            ]}
          />
        }
      </div>
    </main>
  );
};

export default RetailUsers;
