import Button from "components/button";
const ForgotPassword = () => {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-10">
        <div className="flex flex-shrink-0 items-center">
          <img className="w-48" src="/BetMan.png" alt="Logo" width={110} />
        </div>
        <div className="border-2 p-10 rounded-md flex flex-col gap-4">
          <h1 className="text-2xl ">Forgot Password</h1>
          <form className="flex flex-col gap-4" action="">
            <div className="flex  flex-col gap-2">
              <h1>
                Username: <span className="text-red-500">*</span>
              </h1>
              <input
                className="bg-transparent w-full text-gray-700 mr-3 py-2 rounded border-2 leading-tight focus:outline-none  px-2 focus:ring-primary focus:ring"
                type="text"
                name="username"
                id="username"
                placeholder="Username"
              />
              <h1 className="flex items-center justify-center gap-1">
                <span className="text-red-500">*</span> Required
              </h1>
            </div>
            <div className="flex items-center justify-center gap-4">
              <Button variant="secondary">
                <span>Reset Password</span>
              </Button>
              <Button variant="secondary">
                <span>Back to Login</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
