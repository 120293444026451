import { Table } from "components";
import Button from "components/button";
import { table_data1 } from "mock/table_data";
import React from "react";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";

const RetailRoles = () => {
  return (
    <main className="p-4">
      <h1 className="text-xl">Retail Roles</h1>
      <div className="w-full my-5  bg-gray-200">
        <Table data={table_data1.data} columns={table_data1.columns} />
      </div>
    </main>
  );
};

export default RetailRoles;
