import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";

import { classNames } from "./classes";

interface Props {
  label: string;
  options: string[];
  hadleSelected?:(e)=>any
}

const DropDown = ({ label, options,hadleSelected }: Props) => {
  const [selected, setSelected] = useState(options[0]);

  const handleslection =(e)=>{
    setSelected(e)
    hadleSelected&&hadleSelected(e)
  }
  return (
    <div className="">
      <Listbox value={selected} onChange={(e)=>handleslection(e)}>
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
              {label}
            </Listbox.Label>
            <div className="relative mt-1">
              <Listbox.Button className="relative w-full cursor-default rounded-md bg-gray-100 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                <span className="flex items-center">
                  <span className="ml-3 block truncate">{selected}</span>
                </span>
                <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                  <BiChevronDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {options.map((option, index) => (
                    <Listbox.Option
                      key={index}
                      className={({ selected, active }) =>
                        classNames(
                          selected || active
                            ? "bg-primary text-white"
                            : "text-gray-900",
                          "relative cursor-default select-none py-2 pl-3 pr-9 transition-colors duration-150  hover:text-white"
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <div className="flex items-center">
                            <span
                              className={classNames(
                                selected ? "font-semibold" : "font-normal",
                                "ml-3 block truncate"
                              )}
                            >
                              {option}
                            </span>
                          </div>

                          {/* {selected ? (
                            <span
                              className={classNames(
                                active ? "text-white" : "text-gray-600",
                                "absolute inset-y-0 right-0 flex items-center pr-4"
                              )}
                            >
                              <AiOutlineCheck
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null} */}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>{" "}
    </div>
  );
};

export default DropDown;
