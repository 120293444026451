import { Tab } from "@headlessui/react";
import { CheckBox, Table } from "components";
import NavBar from "components/Navbar.component";
import AddUserModal from "components/add_user_modal";
import Button from "components/button";
import Footer from "components/footer.component";
import { assigned_users, un_assigned_users } from "mock/table_data";
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { GoInfo } from "react-icons/go";
import { IoMdSettings } from "react-icons/io";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import {
  assignCashirToShopAction,
  assignShopToAdminAction,
  getAllAdminAction,
  unassignCashirAction,
  unassignShopFromAdminAction,
  updateAdminAction,
  updateCashierAction,
} from "stores/ReatilerUser/retailerAction";
import { useAppDispatch, useAppSelector } from "stores/store";

const roleOptions = [
  "super_admin",
  "system_support",
  "retail_support",
  "retail_finance",
];

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const General = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  const dispatch = useAppDispatch();
  const retailUser = useAppSelector((state) => state.retailer.admins);
  const currentUsers = useAppSelector((state) => state);
  const location = useLocation();

  const handleUpdateUser = async () => {
    if (firstName !== "" && lastname !== "" && username !== "") {
      if (lastname.length > 1 && firstName.length > 1 && username.length > 1) {
        try {
          const response = await dispatch(
            updateAdminAction({
              firstName,
              lastname,
              username,
              role,
              currentUserId: currentUser._id,
            })
          );
          if (response.payload) {
            Notification({
              message: "User updated successfully",
              type: "success",
            });
          } else {
            Notification({
              message: "Failed to update User",
              type: "error",
            });
          }
        } catch (error) {
          Notification({
            message: "Failed to update User",
            type: "error",
          });
        }
      } else {
        Notification({
          message: "Name, Surname and Username length must be above 1!",
          type: "error",
        });
      }
    } else {
      Notification({
        message: "Name, Surname and Username must not be empty!",
        type: "error",
      });
    }
  };

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");

    //look for that user id from the users list
    console.log(currentUser);
    retailUser.length > 0 &&
      setCurrentUser(retailUser.find((user) => user._id === rowParam));
    setFirstName(currentUser.firstname);
    setLastname(currentUser.lastname);
    setUsername(currentUser.username);
    setRole(currentUser.roles);
  }, [currentUser]);

  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-5">
        <div>
          <label htmlFor="name">
            Name <span className="text-red-500">*</span>
          </label>
          <input
            name="name"
            id="name"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="surname">
            Surname <span className="text-red-500">*</span>
          </label>
          <input
            name="surname"
            id="surname"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor="Username">
            Username <span className="text-red-500">*</span>
          </label>
          <input
            name="Username"
            id="Username"
            className="w-full px-2 py-1 border-2 rounded-md"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-5">
        <h1 className="text-lg font-bold">
          Role(s) <span className="text-red-500">*</span>
        </h1>

        <div>
          {roleOptions.map((val, i) => {
            return (
              <div key={i} className="flex gap-2 mb-2">
                <input
                  name="role"
                  key={i}
                  className="px-2 py-1 border-2 rounded-md "
                  radioGroup="role"
                  type="radio"
                  checked={val === role}
                  onChange={() => setRole((prevRole) => val)}
                />
                <label htmlFor={`role-${i}`}>{val}</label>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex justify-end py-5 mt-5 border-t-2 ">
        <Button onClick={handleUpdateUser}>
          <div>Update</div>
        </Button>
      </div>
    </div>
  );
};

const RetailUsers = () => {
  const [currentUser, setCurrentUser] = useState<any>({});
  const [key, setKey] = useState(0);
  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkedRowsToUnassign, setcheckedRowsToUnassign] = useState<any[]>([]);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const retailers = useAppSelector((state) => state.retailer.retailers);
  const retailUser = useAppSelector((state) => state.retailer.admins);

  const handAssignToShop = async () => {
    var selectedShopsId = checkedRows.map((item) => item._id); //filter the Ids from the object array
    if (checkedRows.length > 0) {
      try {
        const response = await dispatch(
          assignShopToAdminAction({
            retailerId: selectedShopsId,
            currentuserId: currentUser._id,
            assignedToAll: false,
          })
        );
        if (response.payload) {
          Notification({
            message: "Retailer successfully assigned to Admin",
            type: "success",
          });
          window.history.back();
        } else {
          Notification({
            message: "Failed to assign Retailer to Admin",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Failed to assign Retailer to Admin",
          type: "error",
        });
      }
    }
  };

  const handleAssignToAll = async () => {
    try {
      const response = await dispatch(
        assignShopToAdminAction({
          assignedToAll: true,
          retailerId: retailers.map((retailer) => retailer._id),
          currentuserId: currentUser._id,
        })
      );
      if (response.payload) {
        Notification({
          message: "All Retailers successfully assigned to Admin",
          type: "success",
        });
        window.history.back();
      } else {
        Notification({
          message: "Failed to assign Retailers to Admin",
          type: "error",
        });
      }
    } catch (error) {
      Notification({
        message: "Failed to assign Retailers to Admin",
        type: "error",
      });
    }
  };

  const handleUnassign = async () => {
    if (checkedRowsToUnassign.length > 0) {
      var selectedShopId = checkedRowsToUnassign.map((item) => item._id);

      try {
        const response = await dispatch(
          unassignShopFromAdminAction({
            currentuserId: currentUser._id,
            retailerId: selectedShopId,
          })
        );
        if (response.payload) {
          Notification({
            message: "Retailer successfully unassigned from Admin",
            type: "success",
          });
          window.history.back();
        } else {
          Notification({
            message: "Failed to unassign Retailer from Admin",
            type: "error",
          });
        }
      } catch (error) {
        Notification({
          message: "Failed to unassign Retailer from Admin",
          type: "error",
        });
      }
    }
  };

  const handlecheckedrow = (row: any) => {
    setCheckedRows((prevRows) => [...prevRows, row]);
  };

  const handlecheckedrowToUnassign = (row: any) => {
    setcheckedRowsToUnassign((prevRows) => [...prevRows, row]);
  };

  useEffect(() => {
    //get the partial user info from url param
    const queryParams = new URLSearchParams(location.search);
    const rowParam = queryParams.get("param1");
    retailUser.length > 0 &&
      setCurrentUser(retailUser.find((user) => user._id === rowParam));
    setKey((prevKey) => prevKey + 1);
  }, [currentUser]);

  useEffect(() => {
    //Remove Duplicate for assign
    const uniqueIds = new Set();
    checkedRows.some((obj) => {
      if (uniqueIds.has(obj._id)) {
        setCheckedRows((prevRows) =>
          prevRows.filter((prevRow) => String(prevRow._id) !== String(obj._id))
        );
      }
      uniqueIds.add(obj._id);
    });
    setKey((prevKey) => prevKey + 1);
  }, [checkedRows]);

  useEffect(() => {
    //Remove Duplicate from unssign
    const uniqueIds = new Set();
    checkedRowsToUnassign.some((obj) => {
      if (uniqueIds.has(obj._id)) {
        setcheckedRowsToUnassign((prevRows) =>
          prevRows.filter((prevRow) => String(prevRow._id) !== String(obj._id))
        );
      }
      uniqueIds.add(obj._id);
    });
    setKey((prevKey) => prevKey + 1);
  }, [checkedRowsToUnassign]);

  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-5 ">
        <h1 className="font-[600]">
          Assigned Retailers (Total: {currentUser.assignedTo?.length})
        </h1>
        <div className="flex flex-col gap-5 bg-gray-200">
          <Table
            key={key}
            data={
              currentUser.assignedTo != undefined &&
              retailers.filter((retailer) =>
                currentUser.assignedTo.includes(retailer._id)
              )
            }
            columns={[
              {
                header: "",
                id: "checkbox2",
                size: 10,
                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrowToUnassign(row.row.original);
                  };
                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        checked={
                          checkedRowsToUnassign.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  );
                },
              },

              {
                header: "Retailer Name",
                accessorKey: "username",
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handleUnassign}>
            <div>Unassign Selected</div>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-5 ">
        <h1 className="font-[600]">
          <span className="mr-7">Unassigned Retailers</span>

          <Button onClick={handleAssignToAll}>
            <div>Assign to All</div>
          </Button>
        </h1>
        <div>
          <Table
            data={
              currentUser.assignedTo != undefined &&
              retailers.filter(
                (retailer) => !currentUser.assignedTo.includes(retailer._id)
              )
            }
            key={key}
            columns={[
              {
                header: "",
                id: "checkbox2",
                size: 10,
                cell: (row: any) => {
                  const handleCheckboxChange = () => {
                    handlecheckedrow(row.row.original);
                  };
                  return (
                    <div className="flex items-center justify-start">
                      <input
                        type="checkbox"
                        checked={
                          checkedRows.filter(
                            (prevRow) =>
                              String(prevRow._id) === row.row.original._id
                          ).length > 0
                        }
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  );
                },
              },
              {
                header: "name",
                accessorKey: "username",
              },
            ]}
          />
        </div>
        <div>
          <Button onClick={handAssignToShop}>
            <div>Assign Selected</div>
          </Button>
        </div>
      </div>
    </div>
  );
};
const RetailOperators = () => {
  return (
    <div className="flex flex-col gap-5">
      <div className="flex flex-col gap-5 ">
        <h1>Assigned Operators</h1>
        <div className="flex flex-col gap-5 bg-gray-200">
          <Table data={assigned_users.data} columns={assigned_users.columns} />
        </div>
        <div>
          <Button>
            <div>Unassign Selected</div>
          </Button>
        </div>
      </div>
      <div className="flex flex-col gap-5 ">
        <h1>Unassigned Users</h1>
        <div>
          <Table
            data={un_assigned_users.data}
            columns={un_assigned_users.columns}
          />
        </div>
        <div>
          <Button>
            <div>Assign Selected</div>
          </Button>
        </div>
      </div>
    </div>
  );
};

const tabs = [
  { name: "General", tab: <General /> },
  { name: " Operators", tab: <RetailOperators></RetailOperators> },
  { name: "Retailers", tab: <RetailUsers></RetailUsers> },
];

const BackOfficeUserDetail = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllAdminAction());
  }, []);

  const retailUser = useAppSelector((state) => state.retailer.admins);
  return (
    <div key={retailUser?.length}>
      <div className="p-1 bg-gray-300">
        <Tab.Group>
          <Tab.List className="bg-gray-300">
            {tabs.map((tab) => (
              <Tab
                key={tab.name}
                className={({ selected }) => `border border-gray-500
              px-4 py-2 rounded-md 
              rounded-b-none
              focus:outline-none focus:ring-transparent ring-offset-2 ring-offset-gray-300 ring-white
              ${selected ? "bg-white border-b-white" : "bg-gray-300"}
              `}
              >
                {tab.name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="px-5 bg-white border-gray-500">
            {tabs.map((tab) => (
              <Tab.Panel key={tab.name} className="p-5 rounded-md ">
                {tab.tab}
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <div className="flex justify-center mt-5">
        <Button variant="secondary" onClick={() => window.history.back()}>
          <div>Back to List</div>
        </Button>
      </div>
    </div>
  );
};

export default BackOfficeUserDetail;
