import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@mantine/dates";

interface DatePickerProps {
  setDateTime?: (dateTime: any) => void;
  defaultDate?: string;
}
const DatePicker: React.FC<DatePickerProps> = ({
  setDateTime,
  defaultDate,
}) => {
  const initialDateTime = defaultDate ? new Date(defaultDate) : new Date();

  // Set time part of the date to 00:00
  initialDateTime.setHours(0, 0, 0, 0);

  const [selectedDateAndTime, setSelectedDateAndTime] =
    useState(initialDateTime);

  useEffect(() => {
    var dateTime = selectedDateAndTime.toISOString();
    setDateTime && setDateTime(dateTime);
  }, [selectedDateAndTime, setDateTime]);

  return (
    <DateTimePicker
      clearable
      defaultValue={defaultDate ? new Date(defaultDate) : new Date(Date.now())}
      placeholder="Pick date and time"
      onChange={setSelectedDateAndTime}
      maw={400}
      mx="auto"
      dropdownType="modal"
    />
  );
};

export default DatePicker;
