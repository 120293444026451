import React, { useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import {
  CheckBox,
  Table,
  Pagination,
  DatePicker,
  ChipSelect,
  BasicTable,
} from "../components";
import Button from "../components/button";
import DropDown from "../utils/drop-down";
import Input from "components/input";
import { IoIosRefresh } from "react-icons/io";
import { RiBrush2Fill } from "react-icons/ri";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { retail_balance_adjustment } from "mock/table_data";
import { OPERATOR, OPERATORGROUP, RETAILERS, RETAIL_USERS } from "mock/mock";
import AddRetailTransactionModal from "components/add_transaction_modal";

const BalanceAdjustment = () => {
  const [selectedOperatorGroup, setSelectedOperatorGroup] = useState<string[]>(
    []
  );
  const [selectedOperator, setSelectedOperator] = useState<string[]>([]);
  const [selecteRetailers, setSelectedRetailers] = useState<string[]>([]);
  const [selectedRetailUser, setSelectedRetailUser] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <main className="p-4">
      <h1 className="text-xl">Retail Balance Adjustment</h1>

      <div className="w-full bg-gray-200">
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-4">
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Operator
              </h1>
              <ChipSelect
                includeSelectAll={true}
                options={OPERATOR}
                selected={selectedOperator}
                setSelected={setSelectedOperator}
                onChange={setSelectedOperator}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retailers
              </h1>
              <ChipSelect
                includeSelectAll={true}
                options={RETAILERS}
                selected={selecteRetailers}
                setSelected={setSelectedRetailers}
                onChange={setSelectedRetailers}
              />
            </div>

            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retail Users
              </h1>
              <ChipSelect
                options={RETAIL_USERS}
                selected={selectedRetailUser}
                setSelected={setSelectedRetailUser}
                onChange={setSelectedRetailUser}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end gap-2 p-4 mt-4">
          <Button>
            <div className="flex items-center gap-2">
              <IoIosRefresh className="text-white" />
              <span>Submit</span>
            </div>
          </Button>
          <Button variant="secondary">
            <div className="flex items-center gap-2">
              <BsFileEarmarkSpreadsheet />
              <span>Export to Excel</span>
            </div>
          </Button>
          <Button onClick={handleModal}>
            <div className="flex items-center gap-2">
              <RiBrush2Fill />
              <span>Add Transaction</span>
            </div>
          </Button>
        </div>
        <Table
          data={retail_balance_adjustment.data}
          columns={retail_balance_adjustment.columns}
        />
        {showModal && (
          <AddRetailTransactionModal open={showModal} onClose={handleModal} />
        )}
      </div>
    </main>
  );
};

export default BalanceAdjustment;
