import DropDown from "utils/drop-down";
import Modal from "./modal";
import { TRANSACTION_TYPE } from "mock/mock";
import Input from "./input";
import Button from "./button";

interface AddRetailTransactionModalInterface {
  open: boolean;
  onClose: () => void;
}

const AddRetailTransactionModal = ({
  open,
  onClose,
}: AddRetailTransactionModalInterface) => {
  return (
    <Modal open={open} onClose={onClose}>
      <div className="p-4 flex flex-col gap-4">
        <DropDown label="Transaction Type" options={TRANSACTION_TYPE} />
        <h1>Amount</h1>
        <div>
          <Input />
        </div>
        <div className="flex justify-end gap-2 mt-4">
          <Button>
            <div>Submit</div>
          </Button>{" "}
          <Button onClick={onClose} variant="secondary">
            <div>Cancle</div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddRetailTransactionModal;
