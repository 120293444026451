import React, { useState, useEffect } from "react";
import { Table, DatePicker, ChipSelect } from "../components";
import Button from "../components/button";
import DropDown from "../utils/drop-down";
import { IoIosRefresh } from "react-icons/io";
import { RiBrush2Fill } from "react-icons/ri";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { cashier_balance } from "mock/table_data";
import { RETAILERS, RETAIL_USERS } from "mock/mock";

const CashierBalances = () => {
  const [selecteRetailers, setSelectedRetailers] = useState<string[]>([]);
  const [selectedRetailUser, setSelectedRetailUser] = useState<string[]>([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [dates, setDates] = useState<any>();
  const [formdatesKey, setFromDatesKey] = useState(0);
  const [todateskey, setToDatesKey] = useState(0);

  useEffect(() => {
    const calculateFromToDate = (option) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      let fromDate, toDate;

      switch (option) {
        case "Today":
          fromDate = new Date();
          toDate = new Date();
          break;
        case "Yesterday":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 1);
          toDate.setDate(toDate.getDate() - 1);
          break;
        case "This week":
          fromDate = new Date(
            currentDate.setDate(currentDate.getDate() - currentDate.getDay())
          );
          toDate = new Date();
          break;
        case "Previous week":
          fromDate = new Date(
            currentDate.setDate(
              currentDate.getDate() -
                currentDate.getDay() +
                (currentDate.getDay() === 0 ? -6 : -13)
            )
          );
          toDate = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 7
            )
          );
          break;
        case "Last 7 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 7);
          break;
        case "Last 30 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 30);
          break;
        case "This Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth()
          );
          toDate = new Date();
          break;
        case "Previous Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1
          );
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
          break;

        default:
          break;
      }

      return { fromDate, toDate };
    };

    const { fromDate, toDate } = calculateFromToDate(dates);
    setFrom(fromDate);
    setTo(toDate);
    setFromDatesKey((prevKey) => prevKey + 1);
    setToDatesKey((prevKey) => prevKey + 1);
  }, [dates]);
  return (
    <main className="p-4">
      <h1 className="text-xl">Cahsier Balances (Cashups)</h1>

      <div className="w-full p-4 bg-gray-200">
        <p className="ml-3 font-bold">
          Please select desired filters and click the submit button to generate
          the report.
        </p>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-4">
            <div>
              <DropDown
                label="Dates"
                options={[
                  "Today",
                  "Yesterday",
                  "This week",
                  "Previous week",
                  "Last 7 Days",
                  "Last 30 Days",
                  "This Month",
                  "Previous Month",
                  "Custom",
                ]}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                From Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={formdatesKey}
                defaultDate={from}
                setDateTime={(e) => setFrom(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                To Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={todateskey}
                defaultDate={to}
                setDateTime={(e) => setTo(e)}
              />
            </div>
            <div></div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retailers
              </h1>
              <ChipSelect
                includeSelectAll={true}
                options={RETAILERS}
                selected={selecteRetailers}
                setSelected={setSelectedRetailers}
                onChange={setSelectedRetailers}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retail Users
              </h1>
              <ChipSelect
                options={RETAIL_USERS}
                selected={selectedRetailUser}
                setSelected={setSelectedRetailUser}
                onChange={setSelectedRetailUser}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2 p-4 mt-4">
          <Button>
            <div className="flex items-center gap-2">
              <IoIosRefresh className="text-white" />
              <span>Submit</span>
            </div>
          </Button>
          <Button variant="secondary">
            <div className="flex items-center gap-2">
              <BsFileEarmarkSpreadsheet />
              <span>Export to Excel</span>
            </div>
          </Button>
          <Button>
            <div className="flex items-center gap-2">
              <RiBrush2Fill />
              <span>Clear</span>
            </div>
          </Button>
        </div>
        <Table data={cashier_balance.data} columns={cashier_balance.columns} />
      </div>
    </main>
  );
};

export default CashierBalances;
