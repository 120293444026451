import { Table } from "components";
import { back_office_roles } from "mock/table_data";
import React from "react";

const BankOfficeRoles = () => {
  return (
    <main className="p-4">
      <h1 className="mb-10 text-xl">Bank Office Roles</h1>
      <div className="w-full bg-gray-200">
        {/* <Table
          data={back_office_roles.data}
          columns={back_office_roles.columns}
        /> */}
        No data to display
      </div>
    </main>
  );
};

export default BankOfficeRoles;
