import {
  MOCK_DATA1,
  HIGHEST_PAYOUT,
  EXCHANGE_RATES,
  RETAIL_REVENUE,
  RETAIL_USER_REVENUE,
  RETAIL_BET_TABLE_DATA,
} from "./mock";
import { Link } from "react-router-dom";
import { BsInfo } from "react-icons/bs";
import { Menu, Transition } from "@headlessui/react";
import { BiChevronDown } from "react-icons/bi";
import { IoMdSettings } from "react-icons/io";
import { Fragment } from "react";
import { classNames } from "utils/classes";

export const table_data1 = {
  data: MOCK_DATA1,
  columns: [
    {
      header: "Betting Summary ID",
      accessorKey: "betting_summary_id",
      size: 10,
    },
    {
      header: "Game ID",
      accessorKey: "game_id",
    },
    {
      header: "Money",
      accessorKey: "money",
    },
    {
      header: "Player ID",
      accessorKey: "player_id",
    },
    {
      header: "Date",
      accessorKey: "date",
    },
  ],
};
export const dashboard_table_data = {
  data: HIGHEST_PAYOUT,
  columns: [
    {
      header: "",
      accessorKey: "betting_id",
      size: 10,
    },
    {
      header: "",
      accessorKey: "name",
    },
    {
      header: "Player",
      accessorKey: "player_id",
    },
    {
      header: "Date",
      accessorKey: "date",
    },
    {
      header: "Amount",
      accessorKey: "ammount",
    },
    {
      id: "view",
      header: "",
      size: 1,
      accessorFunction: (row: any) => {
        return row.betting_id;
      },
      cell: (row: any) => {
        return (
          <Link to={"/" + row.betting_id}>
            <div className="flex items-center justify-center w-full">
              <button className="px-4 py-2 bg-gray-300 ">
                <div className="w-10 h-10 p-2 bg-gray-400 rounded-full">
                  <BsInfo className="text-2xl text-white cursor-pointer" />
                  {row.player_id}
                </div>
              </button>
            </div>
          </Link>
        );
      },
    },
  ],
};

export const display_currency_data = {
  data: EXCHANGE_RATES,
  columns: [
    {
      header: "From Currency Code",
      accessorKey: "from_currency_code",
      size: 10,
    },
    {
      header: "Exchange Rate",
      accessorKey: "exchange_rate",
    },
    {
      header: "Unoffical Exchange Rate",
      accessorKey: "unoffical_exchange_rate",
    },
  ],
};

export const retial_revnue_table_data = {
  data: RETAIL_REVENUE,
  columns: [
    {
      header: "Retailer",
      accessorKey: "retailerUsername",
    },

    {
      header: "Bets",
      accessorKey: "bets",
    },
    {
      header: "Bet Count",
      accessorKey: "betCount",
    },
    {
      header: "Slip Count",
      accessorKey: "slipCount",
    },
    {
      header: "Bet Winnings",
      accessorKey: "betWinnings",
    },
    {
      header: "Bet Winning Count",
      accessorKey: "betWinningCount",
    },
    {
      header: "Cancellation",
      accessorKey: "cancellations",
    },
    {
      header: "Cancellation Count",
      accessorKey: "cancellationCount",
    },
    {
      header: "Unclaimed Bet Winnings",
      accessorKey: "unclaimed",
    },
    {
      header: "Unclaimed Bet Winning Count",
      accessorKey: "unclaimedCount",
    },
    {
      header: "Redeemed",
      accessorKey: "redeemed",
    },
    {
      header: "Redeemed Count",
      accessorKey: "redeemedCount",
    },
    {
      header: "GGR",
      accessorKey: "ggr",
    },
    {
      header: "Net",
      accessorKey: "net",
    }
  ],
};

export const retial_user_revnue_table_data = {
  data: RETAIL_USER_REVENUE,
  columns: [
    {
      header: "Retailer",
      accessorKey: "retailerUsername",
      footer: "",
    },
    {
      header: "Retail User",
      accessorKey: "cashierUsername",
    },
    {
      header: "Bets",
      accessorKey: "bets",
    },
    {
      header: "Bet Count",
      accessorKey: "betCount",
    },
    {
      header: "Slip Count",
      accessorKey: "slipCount",
    },
    {
      header: "Bet Winnings",
      accessorKey: "betWinnings",
    },
    {
      header: "Bet Winning Count",
      accessorKey: "betWinningCount",
    },
    {
      header: "Cancellation",
      accessorKey: "cancellations",
    },
    {
      header: "Cancellation Count",
      accessorKey: "cancellationCount",
    },
    {
      header: "Unclaimed Bet Winnings",
      accessorKey: "unclaimed",
    },
    {
      header: "Unclaimed Bet Winning Count",
      accessorKey: "unclaimedCount",
    },
    {
      header: "Redeemed",
      accessorKey: "redeemed",
    },
    {
      header: "Redeemed Count",
      accessorKey: "redeemedCount",
    },
    {
      header: "GGR",
      accessorKey: "ggr",
    },
    {
      header: "Net",
      accessorKey: "net",
    }
   
  ],
};

export const retail_bet_table_data = {
  data: RETAIL_BET_TABLE_DATA,
  columns: [
    {
      header: "Bet ID",
      accessorKey: "_id",
    },
    {
      header: "Slip ID",
      accessorKey: "slipId",
    },
    {
      header: "Retailer",
      accessorKey: "retailerName",
    },

    {
      header: "Game",
      accessorKey: "gameType",
    },
    {
      header: " Selection Date",
      accessorKey: "created_at",
    },
    {
      header: "Bet Type",
      accessorKey: "betType",
    },
    {
      header: "Odds",
      accessorKey: "odds",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Stake",
      accessorKey: "stake",
    },
    {
      header: "Winnings",
      accessorKey: "betWinnings",
    },
    { header: "Unpaid Winnings", accessorKey: "unpaid_winnings" },
    {
      header: "Submit By",
      accessorKey: "placedBy",
    },
    {
      header: "Cancelled By",
      accessorKey: "cancelled_by",
    },
    {
      header: "Cancelled Date",
      accessorKey: "cancelled_date",
    },
    {
      header: "Redeemed By",
      accessorKey: "redeemed_by",
    },
    {
      header: "Redeemed Date",
      accessorKey: "redeemed_date",
    },
  ],
};

export const opertaors_table_data = {
  data: [
    {
      guid: "Not Set",
      type: "Company",
      name: "Cross Sport Betting",
      status: "Active",
      platform: "Etvirtual Gaming",
      created: "Not set",
    },
  ],
  columns: [
    {
      header: "Guid",
      accessorKey: "guid",
    },
    {
      header: "Type",
      accessorKey: "type",
    },
    {
      header: "Name",
      accessorKey: "name",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Platform",
      accessorKey: "platform",
    },
    {
      header: "Created",
      accessorKey: "created",
    },
    {
      id: "action",
      header: "",
      cell: (row: any) => {
        return (
          <Menu as="div" className="relative text-left">
            <div>
              <Menu.Button className="inline-flex items-center justify-start w-full px-3 py-2 font-bold text-black bg-gray-400 rounded-md xmd:justify-center gap-x-1 text-md">
                <span>Action (s)</span>
                {
                  <BiChevronDown
                    className="w-5 h-5 -mr-1 "
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
            </div>
          </Menu>
        );
      },
    },
  ],
};

export const back_office_users = {
  data: [
    {
      full_name: "Aman Solomon (aman.solomon)",
      username: "aman.solomon",
      email: "xtremesport@gmail.com",
      status: "Active",
      is_locked: "false",
      roles: "Retail Supervisor, Retail Support",
    },
  ],
  columns: [
    {
      header: "FullName",
      id: "FullName",
      cell: (row: any) => {
        return (
          <p>
            {row.row.original.firstname} {row.row.original.lastname}
          </p>
        );
      },
    },
    {
      header: "Username",
      accessorKey: "username",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Is Locked",
      accessorKey: "isLocked",
    },
    {
      header: "Roles",
      id: "FullName",
      cell: (row: any) => {
        return (
          <p>
            {row.row.original.roles.length > 0 &&
              row.row.original.roles.map((role) => {
                <p>{role}</p>;
              })}{" "}
          </p>
        );
      },
    },
    {
      header: "",
      id: "action",
      cell: (row: any) => {
        return (
          <Menu
            onClick={(e) => {
              e.stopPropagation();
            }}
            as="div"
            className="relative text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center justify-start w-full px-3 py-2 font-bold text-black bg-gray-400 rounded-md xmd:justify-center gap-x-1 text-md">
                <span>Action (s)</span>
                {
                  <BiChevronDown
                    className="w-5 h-5 -mr-1 "
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
            </div>

            {
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="right-0 z-20 w-full py-2 mt-2 origin-top-left bg-gray-400 rounded-md ring-0 focus:outline-none xmd:absolute xmd:w-44">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={"Detail"}
                        className={classNames(
                          active ? "bg-secondary text-gray-100" : "text-black",
                          "block px-4 py-2 text-sm font-bold"
                        )}
                      >
                        View
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={"/view"}
                        className={classNames(
                          active ? "bg-secondary text-gray-100" : "text-black",
                          "block px-4 py-2 text-sm font-bold"
                        )}
                      >
                        Deactivate
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to={{
                          pathname: "/Change-password",
                          search: `?param1=${row.row.original._id}&param2=admin`,
                        }}
                        className={classNames(
                          active ? "bg-secondary text-gray-100" : "text-black",
                          "block px-4 py-2 text-sm font-bold"
                        )}
                      >
                        Change Password
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            }
          </Menu>
        );
      },
    },
  ],
};

export const back_office_roles = {
  data: [
    {
      permission: "Dashboard",
    },
  ],
  columns: [
    { header: "Permission", accessorKey: "permission" },
    {
      header: "Kiron Administrator (Dev)",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Kiron Support",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Online Manager (Admin)",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Online Supervisor",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Online Support",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Online Finance",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Retail Manager (Admin)",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Retail Supervisor",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Retail Support",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Cashier Management",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Retail Finance",
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
  ],
};

export const retail_users_table = {
  data: [{ username: "sdas" }],
  columns: [
    {
      header: "FullName",
      id: "FullName",
      cell: (row: any) => {
        return (
          <p>
            {row.row.original.firstname} {row.row.original.lastname}
          </p>
        );
      },
    },
    {
      header: "Username",
      accessorKey: "username",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Status",
      accessorKey: "status",
    },
    {
      header: "Is Locked",
      accessorKey: "isLocked",
    },
    {
      header: "Roles",
      accessorKey: "role",
    },
    {
      header: " Created",
      accessorKey: "created_at",
      cell: (row: any) => {
        // const createdAtValue = row.original.created_at;
        var date = new Date(row.row.original.created_at).toLocaleDateString(
          "en-GB"
        );

        return (
          <div>
            <p>{date}</p>
          </div>
        );
      },
    },
    {
      id: "action",
      header: "",
      cell: (row: any) => {
        return (
          <Menu
            onClick={(e) => {
              e.stopPropagation();
            }}
            as="div"
            className="relative text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center justify-start w-full px-3 py-2 font-bold text-black bg-gray-400 rounded-md xmd:justify-center gap-x-1 text-md">
                <span>Action (s)</span>
                {
                  <BiChevronDown
                    className="w-5 h-5 -mr-1 "
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
            </div>

            {
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="right-0 z-50 w-full py-2 mt-2 origin-top-left bg-gray-400 rounded-md ring-0 focus:outline-none xmd:absolute xmd:w-44">
                  <Menu.Item>
                    {({ active }) => (
                      <>
                        <Link
                          to={{
                            pathname: "/Retail-user-detail",
                            search: `?param1=${row.row.original._id}`,
                          }}
                          className={classNames(
                            active
                              ? "bg-secondary text-gray-100"
                              : "text-black",
                            "block px-4 py-2 text-sm font-bold"
                          )}
                        >
                          View
                        </Link>
                        <Link
                          to={{
                            pathname: "/Change-password",
                            search: `?param1=${row.row.original._id}&param2=cashier`,
                          }}
                          className={classNames(
                            active
                              ? "bg-secondary text-gray-100"
                              : "text-black",
                            "block px-4  text-sm font-bold"
                          )}
                        >
                          Change Password
                        </Link>
                      </>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            }
          </Menu>
        );
      },
    },
  ],
};

export const retailers_table = {
  data: [{ guid: "retailer1" }, { guid: "retailer2" }],
  columns: [
    {
      header: "Guid",
      accessorKey: "_id",
    },
    {
      header: "Name",
      accessorKey: "username",
    },
    {
      header: "Status",
      accessorKey: "isActive",
    },
    {
      header: "Operator",
      accessorKey: "operator",
    },
    {
      header: " Created",
      accessorKey: "created_at",
      cell: (row: any) => {
        // const createdAtValue = row.original.created_at;
        var date = new Date(row.row.original.created_at).toLocaleDateString(
          "en-GB"
        );

        return (
          <div>
            <p>{date}</p>
          </div>
        );
      },
    },
    {
      id: "action",
      header: "",
      cell: (row: any) => {
        return (
          <Menu
            onClick={(e) => {
              e.stopPropagation();
            }}
            as="div"
            className="relative text-left"
          >
            <div>
              <Menu.Button className="inline-flex items-center justify-start w-full px-3 py-2 font-bold text-black bg-gray-400 rounded-md xmd:justify-center gap-x-1 text-md">
                <span>Action (s)</span>
                {
                  <BiChevronDown
                    className="w-5 h-5 -mr-1 "
                    aria-hidden="true"
                  />
                }
              </Menu.Button>
            </div>

            {
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="right-0 z-50 w-full py-2 mt-2 origin-top-left bg-gray-400 rounded-md ring-0 focus:outline-none xmd:absolute xmd:w-44">
                  <Menu.Item>
                    {({ active }) => (
                      <>
                        <Link
                          to={{
                            pathname: "/Retailer-detail",
                            search: `?param1=${row.row.original._id}&param2=retailer`,
                          }}
                          className={classNames(
                            active
                              ? "bg-secondary text-gray-100"
                              : "text-black",
                            "block px-4 py-2 text-sm font-bold"
                          )}
                        >
                          View
                        </Link>
                        <Link
                          to={{
                            pathname: "/Change-password",
                            search: `?param1=${row.row.original._id}&param2=retailer`,
                          }}
                          className={classNames(
                            active
                              ? "bg-secondary text-gray-100"
                              : "text-black",
                            "block px-4 py-2 text-sm font-bold"
                          )}
                        >
                          Change password
                        </Link>
                      </>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            }
          </Menu>
        );
      },
    },
  ],
};

export const retail_balance_adjustment = {
  data: [],
  columns: [
    {
      id: "checkbox",
      size: 10,
      header: (header: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-center">
            <input type="checkbox" />
          </div>
        );
      },
    },
    {
      header: "Retailer",
      accessorKey: "retailer",
    },
    {
      header: "Retail User",
      accessorKey: "retail_user",
    },
    {
      header: "Currency",
      accessorKey: "cash_balance",
    },
    {
      header: "Cash Yield Limit",
      accessorKey: "cash_yield_limit",
    },
    {
      header: "Credit Balance",
      accessorKey: "credit_balance",
    },
    {
      header: "Credit Yield Limit",
      accessorKey: "credit_yield_limit",
    },
    {
      header: "Commission Balance",
      accessorKey: "commission_balance",
    },
  ],
};

export const retail_user_transactions = {
  data: [{}],
  columns: [
    {
      header: "Reference",
      accessorKey: "reference",
      footer: (foot: any) => {
        return (
          <div>
            <h1>Page Total</h1>
            <h2>Grand Total</h2>
          </div>
        );
      },
    },
    { header: "Retailer", accessorKey: "retailer" },
    { header: "User", accessorKey: "user" },
    { header: "Date", accessorKey: "date" },
    { header: "TransactionType", accessorKey: "transactiontype" },
    { header: "Amount", accessorKey: "amount" },
    { header: "Tax", accessorKey: "tax" },
    { header: "Description", accessorKey: "description" },
    { header: "BetMan Transaction ID", accessorKey: "betman_transaction_id" },
    { header: "Slip Reference", accessorKey: "slip_reference" },
    { header: "Slip ID", accessorKey: "slip_id" },
  ],
};

export const cashier_balance = {
  data: [],
  columns: [
    { header: "Retailer", accessorKey: "retailer" },
    { header: "Retailer User", accessorKey: "retailer_user" },
    { header: "From Date", accessorKey: "from_date" },
    { header: "To Date", accessorKey: "to_date" },
    { header: "Start Balance", accessorKey: "start_balance" },
    { header: "Deposits", accessorKey: "deposits" },
    { header: "Bets", accessorKey: "bets" },
    { header: "Cancellations", accessorKey: "cancellations" },
    { header: "Redeemed", accessorKey: "redeemed" },
    { header: "Withdraws", accessorKey: "withdrawals" },
    { header: "End Balance", accessorKey: "end_balance" },
    { header: "Commission", accessorKey: "commission" },
    { header: "NetCash", accessorKey: "net_cash" },
  ],
};

export const assigned_users = {
  data: [{ retailer_name: "SSB_53" }],
  columns: [
    {
      header: "",
      id: "checkbox",
      size: 10,
      cell: (row: any) => {
        const handleCheckboxChange = () => {
          // handlecheckedrow( row.row.original);
        };
        return (
          <div className="flex items-center justify-start">
            <input
              type="checkbox"
              // checked={checkedRows.filter((prevRow) => String(prevRow._id) === row.row.original._id).length>0}
              onChange={handleCheckboxChange}
            />
          </div>
        );
      },
    },

    {
      header: "Retailer Name",
      accessorKey: "username",
    },

    {
      header: "",
      id: "action",
      cell: (row: any) => {
        return (
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
              }}
              className="inline-flex items-center justify-start px-2 py-2 font-bold text-black bg-gray-400 rounded-md w-44 xmd:justify-center gap-x-4 text-md"
            >
              {<IoMdSettings className="w-5 h-5 -mr-1 " aria-hidden="true" />}
              <span>Settings</span>
            </button>
          </div>
        );
      },
    },
  ],
};
export const un_assigned_users = {
  data: [],
  columns: [
    {
      header: "",
      id: "checkbox",
      size: 10,
      cell: (row: any) => {
        return (
          <div className="flex items-center justify-start">
            <input
              type="checkbox"
              // checked={isChecked}
              onChange={() => {
                // isChecked? isChecked=false:isChecked=true

                localStorage.setItem(
                  "selectedRetailerToAssignCashier",
                  row.row._valuesCache.username
                );
              }}
            />
          </div>
        );
      },
    },
    {
      header: "name",
      accessorKey: "username",
    },
  ],
};
export const un_assigned_retailers = {
  data: [],
  columns: [
    {
      header: "",
      id: "checkbox",
      size: 10,
      cell: (row: any, { onCheckboxChange }) => {
        const isChecked = { value: false };

        const handleCheckboxChange = () => {
          isChecked.value = !isChecked.value;
          onCheckboxChange &&
            onCheckboxChange(isChecked.value, row.row.original);
        };

        return (
          <div className="flex items-center justify-start">
            <input type="checkbox" onChange={handleCheckboxChange} />
          </div>
        );
      },
    },
    {
      header: "name",
      accessorKey: "username",
    },
  ],
};
