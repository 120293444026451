import React from "react";
import { Link } from "react-router-dom";

const ErrorPage = () => {
  return (
    <section className="flex flex-col items-center justify-center h-screen bg-gray-200">
      <h1 className="mb-4 text-red-500 text-9xl">404</h1>
      <div className="p-8 text-center bg-white rounded shadow-md">
        <h3 className="mb-4 text-2xl text-gray-700">
          Oops! It seems like you've wandered into uncharted territory.
        </h3>
        <p className="mb-4 text-gray-600">
          The page you're searching for might have taken a vacation or ventured
          into a digital black hole. Our team of digital detectives is on the
          case, working hard to bring it back.
        </p>
        <p className="mb-4 text-gray-600">
          While we search for the missing page, you can try a few things:
        </p>

        <div className="pt-5">
          <Link
            to={"/"}
            className="px-6 py-3 my-4 text-white transition duration-300 bg-green-500 rounded hover:bg-green-600"
          >
            Back Home
          </Link>

         
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
