import DropDown from "utils/drop-down";
import Modal from "./modal";
import Input from "./input";
import Button from "./button";
import { toast } from "react-toastify";
import { useState } from "react";

import {
  createCashierAction,
  createAdminAction,
} from "stores/ReatilerUser/retailerAction";
import { useAppDispatch } from "stores/store";

interface AddUserModalInterface {
  open: boolean;
  onClose: () => void;
  userType?: string;
}

const adminroleOptions = [
  "super_admin",
  "system_support",
  "retail_support",
  "retail_finance",
];

const cashierroleOptions = ["Cashier"];

const AddUserModal = ({ userType, open, onClose }: AddUserModalInterface) => {
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [isTestUser, setIsTestUser] = useState(false);
  const [newpassword, setNewPassword] = useState<any>("");
  const [repassword, setRePassword] = useState<any>("");
  const [role, setRole] = useState<any>(null);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const Notification = ({
    message,
    type,
  }: {
    message: string;
    type: string;
  }) => {
    if (type === "error") {
      toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handleAddUser = async () => {
    if (
      newpassword !== "" &&
      firstName !== "" &&
      lastname !== "" &&
      username !== "" &&
      role !== ""
    ) {
      if (newpassword === repassword) {
        if (newpassword.length > 3) {
          try {
            setLoading(true);
            const response = await dispatch(
              createCashierAction({
                password: newpassword,
                firstName,
                lastname,
                username,
                role,
              })
            );

            if (response.payload) {
              setLoading(false);
              Notification({
                message: "Successfully created user",
                type: "success",
              });
              onClose();
            } else {
              Notification({
                message: "Failed to create User",
                type: "error",
              });
              setLoading(false);
            }
          } catch (error) {
            Notification({
              message: "Failed to create user",
              type: "error",
            });
            setLoading(false);
          }
        } else {
          Notification({
            message: "Password legth should be above 4 characters!",
            type: "error",
          });
          setLoading(false);
        }
      } else {
        Notification({
          message: "Password Doesn't match!",
          type: "error",
        });
        setLoading(false);
      }
    } else {
      Notification({
        message: "Please fill all required fields!",
        type: "error",
      });
      setLoading(false);
    }
  };

  const handleAddAdmin = async () => {
    if (
      newpassword !== "" &&
      firstName !== "" &&
      lastname !== "" &&
      username !== "" &&
      role != null
    ) {
      if (newpassword === repassword) {
        if (newpassword.length > 3) {
          try {
            setLoading(true);
            const response = await dispatch(
              createAdminAction({
                password: newpassword,
                firstName,
                lastname,
                username,
                role,
              })
            );
            if (response.payload) {
              setLoading(false);
              Notification({
                message: "Successfully created Admin",
                type: "success",
              });
              onClose();
            } else {
              Notification({
                message: "Failed to create Admin",
                type: "error",
              });
              setLoading(false);
            }
          } catch (error) {
            Notification({
              message: "Failed to create Admin",
              type: "error",
            });
            setLoading(false);
          }
        } else {
          Notification({
            message: "Password legth should be above 4 characters!",
            type: "error",
          });
          setLoading(false);
        }
      } else {
        Notification({
          message: "Password Doesn't match!",
          type: "error",
        });
        setLoading(false);
      }
    } else {
      Notification({
        message: "Please fill all required fields!",
        type: "error",
      });
      setLoading(false);
    }
  };

  return (
    <div style={{ width: "100%", maxHeight: "100vh", overflowY: "auto" }}>
      <Modal open={open} onClose={onClose}>
        <div className="p-2 mb-5 -mx-5 -mt-5 bg-gray-300 ">
          <h1 className="text-lg font-bold ">
            Add {userType ? userType : "User"}
          </h1>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-5">
          <div>
            <label htmlFor="name">
              Name <span className="text-red-500">*</span>
            </label>
            <input
              name="name"
              id="name"
              className="w-full px-2 py-1 border-2 rounded-md"
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="surname">
              Surname <span className="text-red-500">*</span>
            </label>
            <input
              name="surname"
              id="surname"
              className="w-full px-2 py-1 border-2 rounded-md"
              type="text"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
            />
          </div>
          <div>
            <label htmlFor="Username">
              Username <span className="text-red-500">*</span>
            </label>
            <input
              name="Username"
              id="Username"
              className="w-full px-2 py-1 border-2 rounded-md"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>

          <div className="col-span-1">
            <label htmlFor="password">
              New Password <span className="text-red-500">*</span>
            </label>
            <input
              name="password"
              id="password"
              className="w-full px-2 py-1 border-2 rounded-md"
              type="password"
              value={newpassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="col-span-1">
            <label htmlFor="retypepassword">
              Retype Password <span className="text-red-500">*</span>
            </label>
            <input
              name="retypepassword"
              id="retypepassword"
              className="w-full px-2 py-1 border-2 rounded-md"
              type="password"
              value={repassword}
              onChange={(e) => setRePassword(e.target.value)}
            />
          </div>
        </div>
        <h1 className="text-lg font-bold mt-7">
          Role(s) <span className="text-red-500">*</span>
        </h1>
        {userType == "Admin" ? (
          <div className="flex flex-col gap-4 mt-2">
            <div>
              {adminroleOptions.map((val, i) => {
                return (
                  <div key={i} className="flex gap-2 mb-2">
                    <input
                      name="role"
                      key={i}
                      className="px-2 py-1 border-2 rounded-md "
                      radioGroup="role"
                      type="radio"
                      checked={val === role}
                      onChange={() => setRole(val)}
                    />
                    <label htmlFor={`role-${i}`}>{val}</label>
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          userType == "cashier" && (
            <div className="flex flex-col gap-4 mt-2">
              <div>
                {cashierroleOptions.map((val, i) => {
                  return (
                    <div key={i} className="flex gap-2 mb-2">
                      <input
                        name="role"
                        key={i}
                        className="px-2 py-1 border-2 rounded-md "
                        radioGroup="role"
                        type="radio"
                        checked={val === role}
                        onChange={() => setRole(val)}
                      />
                      <label htmlFor={`role-${i}`}>{val}</label>
                    </div>
                  );
                })}
              </div>
            </div>
          )
        )}
        <div className="flex justify-end py-5 mt-5 border-t-2">
          <Button
            onClick={userType == "Admin" ? handleAddAdmin : handleAddUser}
          >
            {loading ? (
              <svg
                aria-hidden="true"
                className="w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            ) : (
              <span>Add User</span>
            )}
          </Button>
          <button
            onClick={onClose}
            className="px-4 border border-gray-600 rounded-md ml-7"
          >
            <span>Close</span>
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
