import React, { Fragment, useEffect } from "react";
import { Link, useLocation, Navigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "stores/store";
import { Menu, Transition } from "@headlessui/react";
import { BiChevronDown, BiSolidDashboard } from "react-icons/bi";
import { Disclosure } from "@headlessui/react";
import { BiBell as BellIcon, BiX as XMarkIcon } from "react-icons/bi";
import { FaBars } from "react-icons/fa";
import { navLinks } from "../utils/nav-links";
import { classNames } from "utils/classes";
import {
  getAllRetailersAction,
  getRetailerUsersAction,
} from "stores/ReatilerUser/retailerAction";

const NavBar = () => {
  // role based restriction to the nav tabs
  const navigationLinks =
    localStorage.getItem("userRole") == "retail_support"
      ? navLinks.filter(
          (link) => link.parent != "Report" && link.parent != "Setup"
        )
      : localStorage.getItem("userRole") == "retail_finance"
      ? navLinks.filter(
          (link) =>
            link.parent != "Setup" &&
            link.parent != "Find" &&
            link.parent != "Retail"
        )
      : localStorage.getItem("userRole") == "system_support"
      ? navLinks.filter((link) => link.parent != "Report")
      : navLinks;

  const dispatch = useAppDispatch();

  useEffect(() => {
    // to get the user datas after refreshing
    dispatch(getAllRetailersAction());
    dispatch(getRetailerUsersAction());
  }, []);

  return (
    <Disclosure as="nav" className="text-white">
      {({ open }) => (
        <>
          <div className="px-2 bg-secondary sm:px-6">
            <div className="relative flex items-center justify-between h-16">
              <div className="flex items-center flex-1 sm:items-stretch sm:justify-start">
                <div className="flex items-center flex-shrink-0 h-5 mt-2">
                  <img
                    className="items-center w-24 h-12"
                    src="/Et-virtual-logo.png"
                    alt="Logo"
                  />
                </div>
                <div className="hidden sm:ml-6 xmd:block">
                  <div className="flex items-center text-xs">
                    <span className="px-3 ">
                      {localStorage.getItem("userRole") != "retail_support" && (
                        <Link to="/" className="flex items-center gap-1">
                          <BiSolidDashboard className="text-xl" /> Dashboard
                        </Link>
                      )}
                    </span>
                    {navigationLinks.map((links, index) => {
                      return (
                        <span key={index}>
                          <Menu
                            as="div"
                            className="relative inline-block text-left"
                          >
                            {links.dropDown.length === 0 && (
                              <Link
                                to={links.parent}
                                className="inline-flex items-center justify-center w-full px-3 py-2 text-xs rounded-md gap-x-1"
                              >
                                {links.icon} {links.parent}
                                {links.dropDown.length > 0 && (
                                  <BiChevronDown
                                    className="w-5 h-5 -mr-1 text-gray-400"
                                    aria-hidden="true"
                                  />
                                )}
                              </Link>
                            )}
                            {links.dropDown.length > 0 && (
                              <div>
                                <Menu.Button className="inline-flex items-center justify-center w-full px-3 py-2 text-xs rounded-md gap-x-1">
                                  {links.icon} {links.parent}
                                  {links.dropDown.length > 0 && (
                                    <BiChevronDown
                                      className="w-5 h-5 -mr-1 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}
                                </Menu.Button>
                              </div>
                            )}

                            {links.dropDown.length > 0 && (
                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute z-10 py-2 mt-2 origin-top-right rounded-sm w-44 bg-secondary ring-0 focus:outline-none">
                                  {links.dropDown?.map((item, index) => {
                                    return (
                                      <Menu.Item key={index}>
                                        {({ active }) => (
                                          <Link
                                            to={item.link}
                                            className={classNames(
                                              active
                                                ? "bg-secondary text-gray-100"
                                                : "text-gray-400",
                                              "block px-4 py-2 text-sm"
                                            )}
                                          >
                                            {item.label}
                                          </Link>
                                        )}
                                      </Menu.Item>
                                    );
                                  })}
                                </Menu.Items>
                              </Transition>
                            )}
                          </Menu>
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 xmd:hidden">
                <div className="absolute inset-y-0 right-0 flex items-center ">
                  {/* Mobile menu button*/}
                  <Disclosure.Button className="relative inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block w-6 h-6" aria-hidden="true" />
                    ) : (
                      <FaBars className="block w-6 h-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
              </div>
              <div className="hidden xmd:flex">
                <ProfileButton />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="xmd:hidden">
            <Disclosure.Button className="absolute z-10 flex flex-col w-full h-screen bg-transparent">
              <div
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="flex flex-col w-full gap-3 px-8 list-none justify-evenly h-fit bg-secondary"
              >
                <hr className="mt-3 border-t-2 border-gray-400" />
                <span className="w-full py-3">
                  <Disclosure.Button
                    as={Link}
                    to="/"
                    className="flex items-center gap-1"
                  >
                    <BiSolidDashboard className="text-xl" /> Dashboard
                  </Disclosure.Button>
                </span>
                {navLinks.map((links, index) => {
                  return (
                    <li key={index}>
                      <Menu as="div" className="relative text-left">
                        <div className="w-full">
                          <Menu.Button className="inline-flex justify-left gap-x-1.5 rounded-md mb-4 text-sm items-center w-full">
                            {links.icon} {links.parent}
                            <BiChevronDown
                              className="w-5 h-5 -mr-1 text-gray-400"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="py-2 rounded-md bg-secondary ring-0 focus:outline-none ">
                            {links.dropDown?.map((item, index) => {
                              return (
                                <Menu.Item key={index}>
                                  {({ active }) => (
                                    <Disclosure.Button
                                      as={Link}
                                      to={item.link}
                                      className={classNames(
                                        active
                                          ? "bg-secondary text-gray-100"
                                          : "text-gray-400",
                                        "block px-4 py-2 text-sm w-full" // Changed class to w-full
                                      )}
                                    >
                                      {item.label}
                                    </Disclosure.Button>
                                  )}
                                </Menu.Item>
                              );
                            })}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </li>
                  );
                })}
                <div className="w-full mb-6">
                  <ProfileButton />
                </div>
              </div>
            </Disclosure.Button>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default NavBar;

const ProfileButton = () => {
  var userame = localStorage.getItem("username");
  var userId = localStorage.getItem("userId");
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    window.location.href = "/login";
  };

  return (
    <Menu
      onClick={(e) => {
        e.stopPropagation();
      }}
      as="div"
      className="relative w-full text-left"
    >
      <div>
        <Menu.Button className="inline-flex md፡w-full sm:w-full justify-start  xmd:justify-center gap-x-2 rounded-md px-3 py-2 text-md  items-center bg-primary">
          <span>{userame}</span>
          {
            <BiChevronDown
              className="w-5 h-5 ml-auto -mr-1"
              aria-hidden="true"
            />
          }
        </Menu.Button>
      </div>

      {
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="right-0 z-10 w-full py-2 mt-2 origin-top-left rounded-md bg-secondary ring-0 focus:outline-none xmd:absolute xmd:z-auto xmd:w-44">
            <Menu.Item>
              {({ active }) => (
                <Link
                  to={{
                    pathname: "/Change-password",
                    search: `?param1=${userId}&param2=admin`,
                  }}
                  className={classNames(
                    active ? "bg-secondary text-gray-100" : "text-gray-400",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Change Password
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Disclosure.Button
                  as={Link}
                  to={"/login"}
                  onClick={handleLogout}
                  className={classNames(
                    active ? "bg-secondary text-gray-100" : "text-gray-400",
                    "block px-4 py-2 text-sm"
                  )}
                >
                  Logout
                </Disclosure.Button>
              )}
            </Menu.Item>
          </Menu.Items>
        </Transition>
      }
    </Menu>
  );
};
