import React from "react";
import { Table } from "../components";
import { opertaors_table_data } from "mock/table_data";

const Operators = () => {
  return (
    <main className="p-4">
      <h1 className="text-xl">Operators</h1>
      <div className="w-full p-4 bg-gray-200">
        <Table
          data={opertaors_table_data.data}
          columns={opertaors_table_data.columns}
          isFilterable={true}
        />
      </div>
    </main>
  );
};

export default Operators;
