import { Menu, Transition } from "@headlessui/react";
import { CheckBox, Table, DatePicker, ChipSelect } from "../components";
import Button from "../components/button";
import React, { useState, useEffect } from "react";
import exportFromJSON from "export-from-json";
import DropDown from "../utils/drop-down";
import Input from "components/input";
import { IoIosRefresh } from "react-icons/io";
import { RiBrush2Fill } from "react-icons/ri";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { retial_revnue_table_data, table_data1 } from "mock/table_data";
import {
  getRetailRevenueAction,
  getAllRetailersAction,
} from "stores/ReatilerUser/retailerAction";
import { useAppDispatch, useAppSelector } from "stores/store";
import { OPERATOR, BET_TYPE_DROP_DOWN, GAME_DROP_DOWN } from "mock/mock";

const RetailRevenue = () => {
  const [selectedRetailers, setSelectedRetailers] = useState<string[]>([]);
  const [selectedRetailersId, setSelectedRetailersId] = useState<string[]>([]);
  const [selectedOperator, setSelectedOperator] = useState<string[]>([]);
  const [selectedBetType, setSelectedBetType] = useState<string[]>([]);
  const [selectedGame, setSelectedGame] = useState<string[]>([]);

  const retailers = useAppSelector((state) => state.retailer.retailers);
  const dispatch = useAppDispatch();

  const tommorowDate = new Date();
  tommorowDate.setDate(tommorowDate.getDate() + 1);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState<any>(tommorowDate);
  const [dates, setDates] = useState("Today");
  const [slipRef, setSlipRef] = useState("");
  const [data, setData] = useState([]);
  const [key, setKey] = useState(0);
  const [datesKey, setDatesKey] = useState(0);
  const [formdatesKey, setFromDatesKey] = useState(0);
  const [todateskey, setToDatesKey] = useState(0);

  const [loading, setLoading] = useState(false);

  let TotalBets = 0;
  let TotalBetWinnings = 0;
  let TotalCancellations = 0;
  let TotalUnclaimed = 0;
  let TotalRedeemed = 0;
  let TotalBetCounts = 0;
  let TotalSlipCount = 0;
  let TotalBetWinningCount = 0;
  let TotalCancellationCount = 0;
  let TotalUnclaimedCount = 0;
  let TotalRedeemedCount = 0;
  let TotalGGR = 0;
  let TotalNet = 0;

  const fetchFilteredData = async () => {
    try {
      setLoading(true);
      const response = await dispatch(
        getRetailRevenueAction({
          startDate: from,
          endDate: to,
          games: selectedGame,
          retailers: selectedRetailersId,
          slipReference: slipRef,
        })
      );

      if (response && response.payload) {
        const dataWithSums = response.payload;
        const datawithSumstoFixed = response.payload.map((item) => {
          return {
            ...item,
            ggr: item.ggr.toFixed(2),
            net: item.net.toFixed(2),
          };
        });

        dataWithSums.forEach((item) => {
          TotalBets += item.bets;
          TotalBetWinnings += item.betWinnings;
          TotalCancellations += item.cancellations;
          TotalUnclaimed += item.unclaimed;
          TotalRedeemed += item.redeemed;
          TotalBetCounts += item.betCount;
          TotalSlipCount += item.slipCount;
          TotalBetWinningCount += item.betWinningCount;
          TotalCancellationCount += item.cancellationCount;
          TotalUnclaimedCount += item.unclaimedCount;
          TotalRedeemedCount += item.redeemedCount;
          TotalGGR += item.ggr;
          TotalNet += item.net;
        });
        const totalRow = {
          retailerUsername: "Total",
          bets: TotalBets,
          betCount: TotalBetCounts,
          slipCount: TotalSlipCount,
          betWinnings: TotalBetWinnings,
          betWinningCount: TotalBetWinningCount,
          cancellations: TotalCancellations,
          cancellationCount: TotalCancellationCount,
          unclaimed: TotalUnclaimed,
          unclaimedCount: TotalUnclaimedCount,
          redeemed: TotalRedeemed,
          redeemedCount: TotalRedeemedCount,
          ggr: TotalGGR.toFixed(2),
          net: TotalNet.toFixed(2),
        };

        setData([...datawithSumstoFixed, totalRow]);
      }
      setKey((prevKey) => prevKey + 1);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSelectedRetailers([]);
    setDates("Today");
    setTo(tommorowDate);
    setSlipRef("");
    setSelectedGame([]);
    setDatesKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const fetchIdsFromRetailers = (namesArray) => {
      const ids = namesArray.map((name) => {
        const retailer = retailers.find(
          (retailer) => retailer.username === name
        );
        return retailer ? retailer._id : null;
      });
      return ids.filter((id) => id !== null); // Remove null values
    };

    setSelectedRetailersId(fetchIdsFromRetailers(selectedRetailers));
  }, [selectedRetailers]);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getAllRetailersAction());
    };

    fetchData();
  }, []);

  useEffect(() => {
    const calculateFromToDate = (option) => {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1);
      let fromDate, toDate;

      switch (option) {
        case "Today":
          fromDate = new Date();
          toDate = new Date(currentDate);
          break;
        case "Yesterday":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 1);
          toDate.setDate(toDate.getDate());
          break;
        case "This week":
          var today = new Date();
          var firstDayOfWeek =
            today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1);
          fromDate = new Date(today.setDate(firstDayOfWeek));
          toDate = new Date(today.setDate(firstDayOfWeek + 6));
          break;
        case "Previous week":
          fromDate = new Date(
            currentDate.setDate(
              currentDate.getDate() -
                currentDate.getDay() +
                (currentDate.getDay() === 0 ? -6 : -13)
            )
          );
          toDate = new Date(
            currentDate.setDate(
              currentDate.getDate() - currentDate.getDay() + 7
            )
          );
          break;
        case "Last 7 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 7);
          break;
        case "Last 30 Days":
          fromDate = new Date();
          toDate = new Date();
          fromDate.setDate(fromDate.getDate() - 30);
          break;
        case "This Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth()
          );
          toDate = new Date();
          break;
        case "Previous Month":
          fromDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() - 1
          );
          toDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
          break;

        default:
          break;
      }

      return { fromDate, toDate };
    };

    const { fromDate, toDate } = calculateFromToDate(dates);
    setFrom(fromDate);
    setTo(toDate);
    setFromDatesKey((prevKey) => prevKey + 1);
    setToDatesKey((prevKey) => prevKey + 1);
  }, [dates]);

  // implementation of exporting

  const exportData = () => {
    const fileName = "retail-revenue-data";
    if (!data) {
      throw new Error("No data provided for export.");
    }
    try {
      const exportType = exportFromJSON.types["csv"];
      exportFromJSON({ data, fileName, exportType });
    } catch (e) {
      console.error("Export failed: Retry again", e);
    }
  };

  return (
    <main className="p-4">
      <h1 className="text-xl">Retail Revenue</h1>

      <div className="w-full p-4 bg-gray-200">
        <p className="ml-3 font-bold">
          Please select desired filters and click the submit button to generate
          the report.
        </p>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-4">
            <div>
              <DropDown
                key={datesKey}
                label="Dates"
                options={[
                  "Today",
                  "Yesterday",
                  "This week",
                  "Previous week",
                  "Last 7 Days",
                  "Last 30 Days",
                  "This Month",
                  "Previous Month",
                  "Custom",
                ]}
                hadleSelected={(e) => setDates(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                From Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={formdatesKey}
                defaultDate={from}
                setDateTime={(e) => setFrom(e)}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                To Date <span className="text-grey-800">*</span>
              </h1>
              <DatePicker
                key={todateskey}
                defaultDate={to}
                setDateTime={(e) => setTo(e)}
              />
            </div>

            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retailers <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={retailers.map((retail) => retail.username)}
                selected={selectedRetailers}
                setSelected={setSelectedRetailers}
                onChange={setSelectedRetailers}
                includeSelectAll={true}
              />
            </div>

            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Game <span className="text-grey-800">*</span>
              </h1>
              <ChipSelect
                options={GAME_DROP_DOWN}
                selected={selectedGame}
                setSelected={setSelectedGame}
                onChange={setSelectedGame}
                includeSelectAll={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-6 gap-x-10 gap-y-4">
            <Button onClick={fetchFilteredData}>
              <div className="flex items-center gap-2">
                <IoIosRefresh className="text-white" />
                <span>Submit</span>
              </div>
            </Button>
            <Button variant="secondary" onClick={exportData}>
              <div className="flex items-center gap-2">
                <BsFileEarmarkSpreadsheet />
                <span>Export to Excel</span>
              </div>
            </Button>
            <Button onClick={handleClear}>
              <div className="flex items-center gap-2">
                <RiBrush2Fill />
                <span>Clear</span>
              </div>
            </Button>
            <div className="p-2 text-white bg-blue-400 rounded">
              No of records:
              {" " + (data.length > 0 ? data.length - 1 : 0)}
            </div>
          </div>
        </div>
        <div className="relative">
          {loading && (
            <div className="absolute bg-gray-500 rounded-full top-1/3 left-1/2">
              <svg
                aria-hidden="true"
                className="w-16 h-16 p-4 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
            </div>
          )}
          <Table
            key={key}
            isFilterable={false}
            data={data}
            columns={retial_revnue_table_data.columns}
          />
        </div>
      </div>
    </main>
  );
};

export default RetailRevenue;
