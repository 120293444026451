interface InputProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement> | undefined) => void;
  placeholder?: string;
  type?: string;
  error?: boolean;
  errorMessage?: string;
  className?: string;
  autoComplete?: string;
  label?: string;
  required?: boolean;
  outline?: boolean;
  rounded?: boolean;
  min?: number;
  max?: number;
  step?: number;
  maxLength?: number;
}

const Input = () => {
  return (
    <div className="w-full flex flex-col">
      <input
        className=" border-2 border-gray-300 rounded-md 
        px-3 py-2 text-sm placeholder-gray-500
        focus:outline-none focus:ring-2 focus:ring-gray-400
        bg-gray-50"
        type="text"
      />
    </div>
  );
};

export default Input;
