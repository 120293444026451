import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { BsX } from "react-icons/bs";

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(" ");
};

export const Chip = ({
  children,
  onClick,
}: {
  children: string;
  onClick: (option: string) => void;
}) => {
  return (
    <div
      onClick={() => onClick(children)}
      className="bg-gray-100 text-gray-800 px-2 py-1 rounded-md cursor-pointer
      hover:bg-gray-200 transition-colors duration-200 ease-in-out
      active:bg-green-500 active:text-white"
    >
      {children}
      <span className="text-gray-400 hover:text-gray-500 transition-colors font-bold">
        <BsX className="inline-block ml-2" />
      </span>
    </div>
  );
};

interface ChipSelectProps {
  options: string[];
  selected: string[];
  onChange: (selected: string[]) => void;
  includeSelectAll?: boolean;
  setSelected: (selected: string[]) => void;
}

const ChipSelect = ({
  options,
  selected,
  onChange,
  includeSelectAll = false,
  setSelected,
}: ChipSelectProps) => {
  const addSelected = (option: string) => {
    //remove from selected
    if (selected.includes(option)) {
      setSelected(selected.filter((s) => s !== option));
      return;
    }
    setSelected([...selected, option]);
  };

  const removeSelected = (option: string) => {
    setSelected(selected.filter((s) => s !== option));
  };

  return (
    <div>
      <Menu className="relative w-full text-left" as="div">
        <Menu.Button
          className="border bg-gray-50 w-full border-gray-300 rounded-md px-1 py-1  focus:outline-none focus:border-indigo-500
        flex justify-between items-center group"
        >
          <div className="flex flex-wrap gap-2">
            {selected.length === 0 && includeSelectAll && (
              <Chip onClick={() => {}}>All</Chip>
            )}
            {selected.map((s) => (
              <Chip key={s} onClick={removeSelected}>
                {s}
              </Chip>
            ))}
          </div>

          <BsX
            onClick={(e) => {
              e.stopPropagation();
              setSelected([]);
            }}
            className="invisible group-hover:visible transition-opacity duration-200 ease-in-out text-gray-400 font-bold text-xl"
          />
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="h-52 overflow-y-scroll absolute right-0 z-10  w-full origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div>
              {includeSelectAll && (
                <Menu.Item>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        onChange([]);
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      )}
                    >
                      All
                    </div>
                  )}
                </Menu.Item>
              )}
              {options.map((o) => (
                <Menu.Item key={o}>
                  {({ active }) => (
                    <div
                      onClick={() => {
                        if (selected.includes(o)) {
                          onChange(selected.filter((s) => s !== o));
                        } else {
                          onChange([...selected, o]);
                        }
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                      )}
                    >
                      {o}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default ChipSelect;
