import Footer from "components/footer.component";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Navbar from "components/Navbar.component";

const RootLayout = () => {

  // const token = localStorage.getItem("token") 
  
  const token = true

  const location = useLocation();
  return (
    token? <>
      <Navbar />
      <div className="px-5 py-3">
        <Outlet />
      </div>
      <Footer />
    </>
    :<Navigate to="/login" state={{from:location}} replace/>
  );
};

export default RootLayout;
