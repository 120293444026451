import { Menu, Transition } from "@headlessui/react";
import React from "react";
import {
  CheckBox,
  Table,
  Pagination,
  DatePicker,
  ChipSelect,
  BasicTable,
} from "../components";
import Button from "../components/button";
import DropDown from "../utils/drop-down";
import Input from "components/input";
import { IoIosRefresh } from "react-icons/io";
import { RiBrush2Fill } from "react-icons/ri";
import { BsFileEarmarkSpreadsheet } from "react-icons/bs";
import { table_data1 } from "mock/table_data";

const RetailActivity = () => {
  return (
    <main className="p-4">
      <h1 className="text-xl">Retail Activity</h1>

      <div className="w-full p-4 bg-gray-200">
        <p className="ml-3 font-bold">
          Please select desired filters and click the submit button to generate
          the report.
        </p>
        <div className="flex flex-col gap-2 p-4">
          <div className="grid items-end grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-10 gap-y-4">
            <div>
              <DropDown
                label="Dates"
                options={[
                  "Today",
                  "Yesterday",
                  "This week",
                  "Previous week",
                  "Last 7 Days",
                  "Last 30 Days",
                  "This Month",
                  "Previous Month",
                  "Custom",
                ]}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                From Date
              </h1>
              <DatePicker />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                To Date
              </h1>
              <DatePicker />
            </div>
            <div></div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retailers
              </h1>
              <ChipSelect
                options={[
                  "Etb - br - Ethiopian Birr",
                  "Eur - € - Euro",
                  "Gbp - £ - British Pound",
                  "Usd - $ - US Dollar",
                ]}
                selected={["Etb - br - Ethiopian Birr"]}
                setSelected={() => {}}
                onChange={() => {}}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Retail Users
              </h1>
              <ChipSelect
                options={[
                  "Etb - br - Ethiopian Birr",
                  "Eur - € - Euro",
                  "Gbp - £ - British Pound",
                  "Usd - $ - US Dollar",
                ]}
                selected={["Etb - br - Ethiopian Birr"]}
                setSelected={() => {}}
                onChange={() => {}}
              />
            </div>
            <div>
              <h1 className="block text-sm font-medium leading-6 text-gray-900">
                Activity Type
              </h1>
              <ChipSelect
                options={[
                  "Etb - br - Ethiopian Birr",
                  "Eur - € - Euro",
                  "Gbp - £ - British Pound",
                  "Usd - $ - US Dollar",
                ]}
                selected={["Etb - br - Ethiopian Birr"]}
                setSelected={() => {}}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2 p-4 mt-4">
          <Button>
            <div className="flex items-center gap-2">
              <IoIosRefresh className="text-white" />
              <span>Submit</span>
            </div>
          </Button>
          <Button variant="secondary">
            <div className="flex items-center gap-2">
              <BsFileEarmarkSpreadsheet />
              <span>Export to Excel</span>
            </div>
          </Button>
          <Button>
            <div className="flex items-center gap-2">
              <RiBrush2Fill />
              <span>Clear</span>
            </div>
          </Button>
        </div>
        <Table data={table_data1.data} columns={table_data1.columns} />
      </div>
    </main>
  );
};

export default RetailActivity;
