import React, { useState } from "react";
import { BiSolidDashboard } from "react-icons/bi";
import { getRetailRevenueAction } from "stores/ReatilerUser/retailerAction";
import { useAppDispatch } from "stores/store";
import { FaSpinner } from "react-icons/fa";
import { toast } from "react-toastify";

const Notification = ({ message, type }: { message: string; type: string }) => {
  if (type === "error") {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
};

const Dashboard = () => {
  const [selectedDate, setSelectedDate] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  let TotalBets = 0;
  let TotalBetWinnings = 0;
  let TotalCancellations = 0;
  let TotalUnclaimed = 0;
  let TotalRedeemed = 0;
  let TotalNet = 0;

  const handleDataFetch = async ({
    fromDate,
    toDate,
  }: {
    fromDate: string;
    toDate: string;
  }) => {
    try {
      setLoading(true);
      const response = await dispatch(
        getRetailRevenueAction({
          startDate: fromDate,
          endDate: toDate,
          games: [],
          retailers: [],
          slipReference: "",
        })
      );

      if (response && response.payload) {
        setData(response.payload);
      }
    } catch (error) {
      Notification({ message: "Something went wrong", type: "error" });
    } finally {
      setLoading(false);
    }
  };

  const handleSelect = (option: string) => {
    setData([]);
    setSelectedDate([option]);

    const subtractDays = (date: Date, days: number) => {
      const result = new Date(date);
      result.setDate(result.getDate() - days);
      return result.toISOString().split("T")[0];
    };

    const AddDays = (date: Date, days: number) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result.toISOString().split("T")[0];
    };

    const currentDate = new Date();

    if (option === "Today") {
      handleDataFetch({
        fromDate: currentDate.toISOString().split("T")[0],
        toDate: AddDays(currentDate, 1),
      });
    } else if (option === "Yesterday") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 1),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "This Week") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, currentDate.getDay() - 1),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "Last Week") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 7 + currentDate.getDay()),
        toDate: subtractDays(currentDate, 1 + currentDate.getDay()),
      });
    } else if (option === "This Month") {
      handleDataFetch({
        fromDate: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-01`,
        toDate: `${currentDate.getFullYear()}-${(currentDate.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          0
        ).getDate()}`,
      });
    } else if (option === "Last 7 days") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 6),
        toDate: currentDate.toISOString().split("T")[0],
      });
    } else if (option === "Last 30 days") {
      handleDataFetch({
        fromDate: subtractDays(currentDate, 29),
        toDate: currentDate.toISOString().split("T")[0],
      });
    }
  };

  const DataTable = ({ data }: { data: any[] }) => {
    data.forEach((item) => {
      TotalBets += item.bets;
      TotalBetWinnings += item.betWinnings;
      TotalCancellations += item.cancellations;
      TotalUnclaimed += item.unclaimed;
      TotalRedeemed += item.redeemed;
      TotalNet += item.net;
    });
    return (
      <div className="grid grid-cols-1 gap-4 m-8 sm:grid-cols-2 md:grid-cols-3">
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Bets</p>
          <p className="text-xl">{TotalBets === 0 ? 0 : TotalBets}</p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Bet Winnings</p>
          <p className="text-xl">
            {TotalBetWinnings === 0 ? 0 : TotalBetWinnings}
          </p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Cancellations</p>
          <p className="text-xl">
            {TotalCancellations === 0 ? 0 : TotalCancellations}
          </p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Unclaimed</p>
          <p className="text-xl">{TotalUnclaimed === 0 ? 0 : TotalUnclaimed}</p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Redeemed</p>
          <p className="text-xl">{TotalRedeemed === 0 ? 0 : TotalRedeemed}</p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">Total Net</p>
          <p className="text-xl">{TotalNet === 0 ? 0 : TotalNet}</p>
        </div>
        <div className="block max-w-sm p-6 text-white bg-white rounded-lg shadow-md hover:bg-gray-100 dark:bg-blue-400 dark:border-blue-700 dark:hover:bg-blue-700">
          <p className="text-lg font-semibold">RTP</p>
          <p className="text-xl">
            {TotalBets === 0
              ? 0
              : ((TotalBetWinnings / TotalBets) * 100).toFixed(2) + "%"}
          </p>
        </div>
      </div>
    );
  };

  return (
    <div>
      <h1
        style={{
          color: "#1E1E1E",
          fontSize: "1.5rem",
          fontWeight: 500,
        }}
      >
        <span className="flex items-center gap-1">
          <BiSolidDashboard className="text-xl text-green-600" /> Dashboard
        </span>
      </h1>
      <h2 className="sm:mb-0">Select a date option to display data</h2>
      <div className="flex flex-col p-4 mx-auto mt-5 text-white sm:flex-row sm:items-center sm:space-x-4 bg-primary">
        <h3 className="sm:mb-0">REVENUE</h3>
        <div className="mt-3 sm:mt-0">
          <DateFilter selectedDate={selectedDate} handleSelect={handleSelect} />
        </div>
      </div>
      {loading ? (
        <div className="flex items-center justify-center h-96">
          <FaSpinner className="text-5xl animate-spin" />
        </div>
      ) : data.length === 0 ? (
        <div className="flex items-center justify-center h-96">
          <h1>No data available</h1>
        </div>
      ) : (
        <DataTable data={data} />
      )}
    </div>
  );
};

const FilterItem = ({
  label,
  checked,
  onChange,
}: {
  label: string;
  checked: boolean;
  onChange: (checked: string) => void;
}) => {
  return (
    <span
      onClick={() => onChange(label)}
      className={`p-1 cursor-pointer
      transition-colors duration-200
       ${
         checked
           ? "bg-black bg-opacity-30"
           : "bg-white bg-opacity-30 hover:text-blue-600"
       }`}
    >
      {label}
    </span>
  );
};

const DateFilter = ({
  selectedDate,
  handleSelect,
}: {
  selectedDate: string[];
  handleSelect: (option: string) => void;
}) => {
  const options = [
    "Today",
    "Yesterday",
    "This Week",
    "Last Week",
    "This Month",
    "Last 7 days",
    "Last 30 days",
  ];

  return (
    <div className="">
      {options.map((option) => {
        return (
          <FilterItem
            key={option}
            label={option}
            checked={selectedDate.includes(option)}
            onChange={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default Dashboard;
