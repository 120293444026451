import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./Auth/authSlice";
import retailerReducer from "./ReatilerUser/retailerSlice";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const store = configureStore({
  reducer: {
    user: userReducer,
    retailer: retailerReducer,
  },
});

export const useAppDispatch: () => typeof store.dispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<ReturnType<typeof store.getState> > = useSelector;
export type RootState = ReturnType<typeof store.getState>;
export default store;
